import React, { useEffect, useState } from 'react'
import { Navbar, Container, Button, Spinner, Row, Col, Card, Form, FloatingLabel, Modal, Accordion } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

export default function AddNewTeacher() {

  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const branchNames = ["Rehberlik", "Türkçe", "Sosyal Bilgiler",  "Din Kültürü", "Matematik", "Geometri", "Fen Bilimleri", "İngilizce" ]

  const [clicked, setClicked] = useState(false)

  const { checkIsExist, createNewTeacher, userData, generatePassword } = useAuth();

  const navigate = useNavigate()

  const handleClick = async () => {
    setClicked(true)
    if (userData && name && surname && branches.length > 0 && username && password.length >= 6) {
      const isExist = await checkIsExist(username)
      if (!isExist) {
        try {
          await createNewTeacher({
            name: name.trim(),
            surname: surname.trim(),
            email: `${username.trim()}@derecelgs.com`,
            branches: branches,
            students: students || [],
            username: username.trim(),
            password: password.trim(),
            role: "teacher",
            organizationId: userData.uid
          })
          setClicked(false)
          navigate("/kurum-paneli/ogretmen-listesi")
        } catch {
          setClicked(false)
          // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
          setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
          handleShow()
        }
      } else {
        let suffix = 2
        let newUsername = `${username}${suffix}`
        let bool = await checkIsExist(newUsername)

        while (bool) {
          suffix += 1
          newUsername = `${username}${suffix}`
          bool = await checkIsExist(newUsername)
        }

        await createNewTeacher({
          name: name.trim(),
          surname: surname.trim(),
          email: `${newUsername.trim()}@derecelgs.com`,
          branches: branches,
          students: students || [],
          username: newUsername.trim(),
          password: password.trim(),
          role: "teacher",
          organizationId: userData.uid
        })

        setClicked(false)
        navigate("/kurum-paneli/ogretmen-listesi")
      }
    } else {
      setClicked(false)
      // alert("Lütfen tüm alanları tam ve doğru doldurun.")
      setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
      handleShow()
    }
  }

  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [branches, setBranches] = useState([])
  const [students, setStudents] = useState([])
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [allStudents, setAllStudents] = useState()

  const turkishToEnglish = (text) => {
    return text
      .replaceAll(/ğ/gim, "g")
      .replaceAll(/ü/gim, "u")
      .replaceAll(/ş/gim, "s")
      .replaceAll(/ı/gim, "i")
      .replaceAll(/ö/gim, "o")
      .replaceAll(/ç/gim, "c");
  }

  // useEffect(() => {
  //   if (username) {
  //     console.log("USERNAME:", username);
  //   }
  // }, [username])

  useEffect(() => {
    if (name && surname && userData) {
      const nameList = name.trim().split(" ")
      let abbrOfName = ""
      if (nameList.length > 1 || name.length + surname.length > 15) {
        nameList.forEach((name) => {
          if (name) {
            abbrOfName += name.split("")[0]
          }
        })
      } else {
        abbrOfName = nameList[0]
      }
      setUsername(userData.tag.toLowerCase() + turkishToEnglish(abbrOfName.toLocaleLowerCase('tr-TR')) + turkishToEnglish(surname.toLocaleLowerCase('tr-TR')))

      const pass = generatePassword()
      setPassword(pass)
    }
  }, [name, surname, userData, generatePassword])

  const handleBranches = async (value) => {
    const isExist = branches.includes(value)
    if (isExist) {
      if (branches.length > 1) {
        try {
          const index = branches.indexOf(value)
          const copy = Array.from(branches)
          copy.splice(index, 1)
          setBranches(copy)
        } catch (error) {
          console.log(error);
        }

      } else {
        setBranches([])
      }
    } else {
      setBranches(prev => [...prev, value])
    }
  }

  const handleStudents = async (value) => {
    const isExist = students.includes(value)
    if (isExist) {
      if (students.length > 1) {
        try {
          const index = students.indexOf(value)
          const copy = Array.from(students)
          copy.splice(index, 1)
          setStudents(copy)
        } catch (error) {
          console.log(error);
        }
       
      } else {
        setStudents([])
      }
    } else {
      setStudents(prev => [...prev, value])
    }
  }

  // useEffect(() => {
  //   if (students && branches) {
  //     console.log("STUDENTS:", students);
  //     console.log("BRANCHES:", branches);
  //   }
  // }, [students, branches])

  useEffect(() => {
    if (userData) {
      const getStudents = async () => {
        const students = [];
        const querySnapshot = await getDocs(query(collection(db, "students"), where("organizationId", "==", userData.uid)));
        querySnapshot.forEach((doc) => {
          students.push(doc.data())
        });
        students.sort((x, y) => {
          return y.createdAt - x.createdAt
        })
        setAllStudents(students)
      }

      getStudents()
    }
  }, [userData])

  // useEffect(() => {
  //   console.log("selectedstudents:", students);
  // }, [allStudents, students])

  const handleQuickActions = async (actionType) => {
    if (actionType === "SELECT-ALL-STUDENTS") {
      const temp = []
      await allStudents.forEach((student) => {
        temp.push(student.uid)
      })
      setStudents(temp)
    }
    else if (actionType === "REMOVE-ALL-STUDENTS") {
      setStudents([])
    }
    else if (actionType === "SELECT-ALL-5-GRADE") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.grade === "5") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-5-GRADE") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.grade === "5") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }
    else if (actionType === "SELECT-ALL-6-GRADE") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.grade === "6") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-6-GRADE") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.grade === "6") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }
    else if (actionType === "SELECT-ALL-7-GRADE") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.grade === "7") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-7-GRADE") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.grade === "7") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }
    else if (actionType === "SELECT-ALL-8-GRADE") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.grade === "8") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-8-GRADE") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.grade === "8") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }
  }

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Öğretmenler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/kurum-paneli/ogretmen-listesi' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Öğretmen Listesi</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Öğretmen Ekle</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      {
        allStudents && <Container style={{ marginBottom: "24px" }}>
          <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
            <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Col lg={3} md={2} sm={1} />
              <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
                <Card >
                  <Card.Body>
                    <Form onSubmit={e => { e.preventDefault(); }}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput1"
                          label="Ad *"
                          className="mb-3"
                        >
                          <Form.Control type='text' placeholder="Ad *" value={name} onChange={(e) => setName(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput1"
                          label="Soyad *"
                          className="mb-3"
                        >
                          <Form.Control type='text' placeholder="Soyad *" value={surname} onChange={(e) => setSurname(e.target.value.trim())} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ pointerEvents: "none", fontWeight: "500" }}>Branşlar ({branches.length}) *</Form.Label>
                        <Form.Switch style={{ padding: "0 6px" }} className="mb-3" aria-label="Default select example">
                          {
                            branchNames.map((branch, index) => (
                              <Form.Check
                                disabled={clicked}
                                key={index}
                                value={branch}
                                type="switch"
                                label={branch}
                                style={{ fontSize: "1rem" }}
                                onChange={(e) => e.target.value && handleBranches(e.target.value)}
                              />
                            ))
                          }
                        </Form.Switch>
                      </Form.Group>

                      {
                        branches.length > 0 &&
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", marginBottom: "18px", padding: "0 8px" }}>
                          <p style={{ margin: "0 4px 0 0", fontSize: "0.9rem", fontWeight: "500", textDecoration: "underline" }}>Seçilenler:</p>
                          {
                            branches.map((branch, index) => (
                              <p key={index} style={{ margin: "0 4px 0 0", fontSize: "0.9rem" }}>{branch}{branches[index + 1] ? "," : null}</p>
                            ))
                          }
                        </div>
                      }


                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ pointerEvents: "none", fontWeight: "500" }}>Takip Edeceği Öğrenciler ({students.length})</Form.Label>

                        <Accordion defaultActiveKey="0" style={{ marginBottom: "16px" }}>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Öğrenci Ekle-Çıkar Hızlı Aksiyonlar</Accordion.Header>
                            <Accordion.Body>
                              <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", gap: "8px", }}>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-STUDENTS")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  Tüm Öğrencileri Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-STUDENTS")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  Tüm Öğrencileri Çıkar
                                </Button>

                                <Button onClick={() => handleQuickActions("SELECT-ALL-5-GRADE")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  5. Sınıfları Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-5-GRADE")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  5. Sınıfları Çıkar
                                </Button>

                                <Button onClick={() => handleQuickActions("SELECT-ALL-6-GRADE")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  6. Sınıfları Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-6-GRADE")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  6. Sınıfları Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-7-GRADE")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  7. Sınıfları Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-7-GRADE")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  7. Sınıfları Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-8-GRADE")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  8. Sınıfları Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-8-GRADE")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  8. Sınıfları Çıkar
                                </Button>
 
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                        <Form.Switch style={{ padding: "0 6px" }} className="mb-3" aria-label="Default select example">
                          {
                            allStudents.map((student, index) => {
                              let label = `${student.name} ${student.surname}`
                              if (student.studentNo) {
                                label += ` - ${student.studentNo}`
                              }
                              label += ` - ${student.grade}`
                              if (student.classCode) {
                                label += ` - ${student.classCode}`
                              }
                              if (student.domain) {
                                label += ` - ${student.domain}`
                              }
                              if (student.lang) {
                                label += ` - ${student.lang}`
                              }

                              return (
                                <Form.Check
                                  checked={students.includes(student.uid)}
                                  disabled={clicked}
                                  key={index}
                                  value={student.uid}
                                  type="switch"
                                  label={label}
                                  style={{ fontSize: "1rem" }}
                                  onChange={(e) => e.target.value && handleStudents(e.target.value)}
                                />
                              )
                            })
                          }
                        </Form.Switch>
                      </Form.Group>

                      {
                        students.length > 0 &&
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", marginBottom: "18px", padding: "0 8px" }}>
                          <p style={{ margin: "0", fontSize: "0.9rem", fontWeight: "500", textDecoration: "underline" }}>Seçilenler:</p>
                          {
                            students.map((uid, index) => {
                              const student = allStudents.find((item) => item.uid === uid)
                              return (
                                <p key={index} style={{ margin: "0", fontSize: "0.9rem" }}>{index + 1}. {student.name} {student.surname} {student.studentNo && `- ${student.studentNo}`} - {student.grade} {student.classCode && `- ${student.classCode}`} {student.domain && `- ${student.domain}`} {student.lang && `- ${student.lang}`}</p>
                              )
                            })
                          }
                        </div>
                      }

                      <div style={{ margin: "0 0 12px 0", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: 0 }}>
                        <Form.Text style={{ marginTop: 0 }} className="text-muted">
                          Yıldız (*) işareti bulunan alanları doldurmak zorunludur.
                        </Form.Text>
                      </div>

                      {
                        clicked
                          ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                            <Spinner
                              style={{ marginRight: "6px" }}
                              as="span"
                              size="sm"
                              aria-hidden="true"
                            />
                          </Button>
                          : <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                            Öğretmen Ekle
                          </Button>
                      }

                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} md={2} sm={1} />
            </Row>
          </Container>

          <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
          >
            <Modal.Header closeButton>
              {/* <Modal.Title>{modalTitle}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              {modalError}
            </Modal.Body>

          </Modal>
        </Container>
      }
    </>
  )
}