import React, { useEffect, useState } from 'react'
import { Row, Nav, Container, Navbar, NavDropdown } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate, Outlet } from 'react-router-dom'

function TeacherPanelLayout() {

  const { logOut, userData } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    if (userData) {
      if (userData.role !== "teacher") {
        navigate("/")
      }
    }
  }, [userData, navigate])



  return (
    <>
      {
        (userData && userData.role === "teacher") &&
        <Container fluid>
          <Row>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary border-bottom">
              <Container>
                <Navbar.Brand href='/ogretmen-paneli'>Öğretmen Paneli</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <NavDropdown title="Öğrenciler" id="collasible-nav-dropdown">
                      <NavDropdown.Item onClick={() => navigate("ogrenci-listesi")}>Öğrenci Listesi</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("konu-takibi")}>Konu Takibi</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("ders-calisma-suresi")}>Ders Çalışma Süresi</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("cozulen-soru-sayisi")}>Çözülen Soru Sayısı</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("denemeler")}>Denemeler</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("cozulemeyen-sorular")}>Çözülemeyen Sorular</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("/ogretmen-paneli")}>Öğrenci Analizleri</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Nav>
                    <NavDropdown title={`${userData.name} ${userData.surname}`} id="collasible-nav-dropdown">
                      <NavDropdown.Item onClick={() => logOut()}>Çıkış Yap</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Row>
          <Row style={{ padding: 0 }}>
            <Container style={{ padding: 0 }}>
              <Outlet />
            </Container>
          </Row>
        </Container>
      }
    </>
  )
}

export default TeacherPanelLayout