import React, { useEffect, useState } from 'react'
import { Container, Button, Card, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

export default function Login() {
  const navigate = useNavigate()

  const { currentUser, userData } = useAuth()

  // useEffect(() => {
  //   if (currentUser) {
  //     navigate("/")
  //   }
  // }, [currentUser, navigate])

  return (
    <>
      {
        (!currentUser) && <Container className='bg-body-tertiary' fluid style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Col lg={3} md={2} sm={1} />
            <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
              <Card >
                <Card.Body>
                  <Button onClick={() => navigate("/kurum-girisi")} style={{ width: "100%" }} variant="primary" type="submit">
                    Kurum Girişi
                  </Button>
                  <Button onClick={() => navigate("/ogretmen-girisi")} style={{ width: "100%", margin: "12px 0" }} variant="primary" type="submit">
                    Öğretmen Girişi
                  </Button>
                  <Button onClick={() => navigate("/veli-girisi")} style={{ width: "100%", marginBottom: "12px" }} variant="primary" type="submit">
                    Veli Girişi
                  </Button>
                  <Button onClick={() => navigate("/ogrenci-girisi")} style={{ width: "100%" }} variant="outline-primary" type="submit">
                    Öğrenci Girişi
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={2} sm={1} />
          </Row>
        </Container>
      }
    </>
  )
}
