import React, { useEffect, useRef, useState } from 'react'
import { Navbar, Container, Button, Spinner, Row, Col, Card, Form, Modal } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { db, storage } from '../../firebaseConfig';
import { getDownloadURL, uploadBytesResumable, ref } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { uuidv4 } from '@firebase/util';
import imageCompression from 'browser-image-compression';

export default function AddNewSolution() {

  const { userData } = useAuth()

  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clicked, setClicked] = useState(false)

  const navigate = useNavigate()

  const [image, setImage] = useState({ preview: "", raw: "" });

  const [question, setQuestion] = useState()

  const params = useParams()

  useEffect(() => {
    const getQuestionData = async () => {
      const uid = params.id
      const docRef = doc(db, "questions", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const dataForQuestion = docSnap.data()
        if (dataForQuestion.organizationId === userData.organizationId) {
          setQuestion(docSnap.data())
        } else {
          navigate("/ogretmen-paneli/cozulemeyen-sorular")
        }
      } else {
        navigate("/ogretmen-paneli/cozulemeyen-sorular")
      }
    }
    getQuestionData()
  }, [params.id, userData, navigate])

  const ref2 = useRef()

  const handleChange = () => {
    ref2.current.click()
  }

  const selectPhoto = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  const addNewSolution = async (data) => {

    setClicked(true)

    const fileName = `${uuidv4()}.${(data.image.name).split(".")[1]}`

    const questionsRef = ref(storage, `solutions/${fileName}`);

    const uploadTask = uploadBytesResumable(questionsRef, data.image);

    uploadTask.on('state_changed',
      (snapshot) => {
        // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;        
      },
      (error) => {
        setClicked(false)
        // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
        setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
        handleShow()
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          const questionRef = doc(db, "questions", params.id);

          await updateDoc(questionRef, {
            status: "solved",
            solutionImage: downloadURL,
            solutionImageFileName: fileName
          });

          setClicked(false)
          navigate("/ogretmen-paneli/cozulemeyen-sorular/" + question.studentId)
        });
      }
    );
  }

  const handleClick = async () => {
    setClicked(true)
    if (userData && image.preview && image.raw) {
      if (image.raw.name.split(".")[1] === "jpg" || image.raw.name.split(".")[1] === "jpeg" || image.raw.name.split(".")[1] === "png") {

        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920
        }

        let compressedFile;

        try {
          compressedFile = await imageCompression(image.raw, options);
        } catch {
          setClicked(false)
          // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
          setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
          handleShow()
        }

        if (compressedFile.size > 1000000) {
          setClicked(false)
          // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
          setImage({ preview: "", raw: "" })
          setModalError("Eklemeye çalıştığınız fotoğraf 1 MB limitini aşmaktadır.")
          handleShow()
        } else {
          try {
            await addNewSolution({
              studentId: userData.uid,
              organizationId: userData.organizationId,
              image: compressedFile,
            })
          } catch (error) {
            console.log("error", error);
            setClicked(false)
            // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
            setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
            handleShow()
          }
        }
      } else {
        setClicked(false)
        // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
        setImage({ preview: "", raw: "" })
        setModalError("Sadece JPG, JPEG veya PNG uzantılı bir dosya ekleyebilirsiniz.")
        handleShow()
      }
    } else {
      setClicked(false)
      // alert("Lütfen tüm alanları tam ve doğru doldurun.")
      setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
      handleShow()
    }
  }

  useEffect(() => {
    if (image && image.raw && image.preview) {
      if (!(image.raw.name.split(".")[1] === "jpg" || image.raw.name.split(".")[1] === "jpeg" || image.raw.name.split(".")[1] === "png")) {
        setImage({ preview: "", raw: "" })
        setModalError("Sadece JPG, JPEG veya PNG uzantılı bir dosya ekleyebilirsiniz.")
        handleShow()
      }
    }
  }, [image])

  const [width, setWidth] = useState(0)
  const elementRef = useRef(null);


  useEffect(() => {
    setWidth(elementRef.current.offsetWidth);
  }, [])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/ogretmen-paneli/cozulemeyen-sorular' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Çözülemeyen Sorular</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Çözüm Ekle</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
          <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Col lg={3} md={2} sm={1} />
            <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
              <Card >
                <Card.Body>
                  <Form onSubmit={e => { e.preventDefault(); }} ref={elementRef} >
                    {/* {question &&
                      <img className='mb-3' src={question.image} alt="" style={{ width: "100%", height: width, objectFit: "contain" }} />
                    } */}
                    {
                      <div>
                        <label htmlFor="upload-button" style={{ width: "100%" }}>
                          {image.preview ? (
                            <img className='mb-3' src={image.preview} alt="" style={{ width: "100%", maxHeight: width, objectFit: "contain" }} />
                          ) : (
                            <Button className='mb-3' onClick={handleChange} style={{ width: "100%" }} variant="outline-primary" >
                              Fotoğraf Seç
                            </Button>
                          )}
                        </label>
                        <input
                          ref={ref2}
                          type="file"
                          id="upload-button"
                          style={{ display: "none" }}
                          onChange={selectPhoto}
                        />
                        <br />
                      </div>
                    }

                    {/* <div style={{ margin: "0 0 12px 0", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: 0 }}>
                      <Form.Text style={{ marginTop: 0, textAlign: "center" }} className="text-muted">
                        Yıldız (*) işareti bulunan alanları doldurmak zorunludur.
                      </Form.Text>
                    </div> */}

                    {
                      clicked
                        ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                          <Spinner
                            style={{ marginRight: "6px" }}
                            as="span"
                            size="sm"
                            aria-hidden="true"
                          />
                        </Button>
                        : <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                          Çözüm Ekle
                        </Button>
                    }

                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={2} sm={1} />
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            {modalError}
          </Modal.Body>

        </Modal>
      </Container>
    </>
  )
}


