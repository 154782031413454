import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../firebaseConfig';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function OrganizationRow({ organization, index }) {

  const navigate = useNavigate()

  const [totalStudents, setTotalStudents] = useState(0)
  const [totalTeachers, setTotalTeachers] = useState(0)

  const calculateTotalCountByOrganizationId = async (organizationId) => {
    let totalStudents = 0
    let totalTeachers = 0

    const querySnapshot = await getDocs(query(collection(db, "students"), where("organizationId", "==", organizationId)));
    querySnapshot.forEach(() => {
      totalStudents += 1
    });

    const querySnapshot2 = await getDocs(query(collection(db, "teachers"), where("organizationId", "==", organizationId)));
    querySnapshot2.forEach(() => {
      totalTeachers += 1
    });

    setTotalStudents(totalStudents)
    setTotalTeachers(totalTeachers)
  }

  useEffect(() => {
    if (organization) calculateTotalCountByOrganizationId(organization.uid)
  }, [organization])

  return (
    <tr key={index} style={{ verticalAlign: "middle" }}>
      <td>{index + 1}</td>
      <td>{organization.name} ({organization.tag})</td>
      <td>{organization.uid}</td>
      <td>{organization.adress}</td>
      <td>{organization.phone}</td>
      <td>{organization.username}</td>
      <td>{organization.password}</td>
      <td>{totalStudents}</td>
      <td>{totalTeachers}</td>
      <td style={{ textAlign: "center" }}>
        <Button onClick={() => navigate("kurum-duzenle/" + organization.uid)} size="sm">Düzenle</Button>
      </td>
    </tr>
  )
}
