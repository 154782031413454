import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyChobKLJcEXSeLQVsAenCNmU_m37sW9-pg",
  authDomain: "derecelgs.firebaseapp.com",
  projectId: "derecelgs",
  storageBucket: "derecelgs.appspot.com",
  messagingSenderId: "661483440678",
  appId: "1:661483440678:web:80807a0540674ca5c5f4e7"
};

const app = initializeApp(firebaseConfig);
const secondaryApp = initializeApp(firebaseConfig, "Secondary")

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const secondaryAuth = getAuth(secondaryApp)

export { auth, db, secondaryAuth, storage }