import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {

  const navigate = useNavigate()

  return (
    <Container fluid style={{ display: "flex", width: "100vw", height: "100vh", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <p style={{ margin: 0, padding: 0, fontWeight: "500", fontSize: "1.2rem"}}>Böyle bir sayfa yok.</p>
      <Button onClick={() => navigate(-1)} variant="outline-primary" style={{ marginTop: "8px"}}>Geri</Button>
    </Container>
  )
}
