import React, { useState, useEffect } from 'react'
import { Container, Form, Button, Card, Row, Col, FloatingLabel, Spinner, Modal } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export default function ParentLogin() {
  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const { login, currentUser, userData, checkIsExistForRole } = useAuth();

  const handleClick = async () => {
    setClicked(true)
    if (username && password.length >= 6) {
      const isExist = await checkIsExistForRole("parent", username)
      if (isExist) {
        try {
          await login(username, password)
          navigate("/veli-paneli")
        } catch {
          setClicked(false)
          // alert("Giriş bilgileriniz hatalı.")
          setModalError("Giriş bilgileriniz hatalı.")
          handleShow()
        }
      } else {
        setClicked(false)
        // alert("Kullanıcı bulunamadı.")
        setModalError("Kullanıcı bulunamadı.")
        handleShow()
      }
    } else {
      setClicked(false)
      // alert("Lütfen giriş bilgilerinizi tam doldurun.")
      setModalError("Lütfen giriş bilgilerinizi tam doldurun.")
      handleShow()
    }
  }

  const navigate = useNavigate()

  // useEffect(() => {
  //   if (currentUser) {
  //     navigate("/")
  //   }
  // }, [currentUser, navigate])

  const [clicked, setClicked] = useState(false)

  return (
    <>
      {
        (!currentUser) && <Container className='bg-body-tertiary' fluid style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Col lg={3} md={2} sm={1} />
            <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
              <Card >
                <Card.Header className='bg-white' style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "8px 0" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {/* <p style={{ margin: "0 8px 0 0", fontSize: "1.6rem" }}>👫</p> */}
                    <p style={{ margin: 0, fontSize: "1.4rem", fontWeight: "500" }}>Veli Girişi</p>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={e => { e.preventDefault(); }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Kullanıcı Adı"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Kullanıcı Adı" value={username} onChange={(e) => setUsername(e.target.value.trim())} />
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <FloatingLabel controlId="floatingPassword" label="Parola">
                        <Form.Control type="password" placeholder="Parola" autoComplete='new-password' value={password} onChange={(e) => setPassword(e.target.value.trim())} />
                      </FloatingLabel>
                    </Form.Group>
                    {
                      clicked
                        ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                          <Spinner
                            style={{ marginRight: "6px" }}
                            as="span"
                            size="sm"
                            aria-hidden="true"
                          />
                        </Button>
                        : <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                          Giriş Yap
                        </Button>
                    }
                  </Form>
                </Card.Body>

              </Card>
            </Col>
            <Col lg={3} md={2} sm={1} />
          </Row>

          <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
          >
            <Modal.Header closeButton>
              {/* <Modal.Title>{modalTitle}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              {modalError}
            </Modal.Body>
  
          </Modal>
        </Container>
      }
    </>
  )
}

