import React, { useEffect, useState } from 'react'
import { Row, Nav, Container, Navbar, NavDropdown } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate, Outlet } from 'react-router-dom'

function ParentPanelLayout() {

  const { logOut, userData } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    if (userData) {
      if (userData.role !== "parent") {
        navigate("/")
      }
    }
  }, [userData, navigate])

  return (
    <>
      {
        (userData && userData.role === "parent") &&
        <Container fluid>
          <Row>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary border-bottom">
              <Container>
                <Navbar.Brand href='/veli-paneli'>Veli Paneli</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <NavDropdown title="Çalışma Takibi" id="collasible-nav-dropdown">
                      <NavDropdown.Item onClick={() => navigate("konu-takibi")}>Konu Takibi</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("ders-calisma-suresi")}>Ders Çalışma Süresi</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("cozulen-soru-sayisi")}>Çözülen Soru Sayısı</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("denemeler")}>Denemeler</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("/veli-paneli")}>Genel Analiz</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Nav>
                    <NavDropdown title={`${userData.name}`} id="collasible-nav-dropdown">
                      <NavDropdown.Item onClick={() => logOut()}>Çıkış Yap</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Row>
          <Row style={{ padding: 0 }}>
            <Container style={{ padding: 0 }}>
              <Outlet />
            </Container>
          </Row>
        </Container >
      }
    </>
  )
}

export default ParentPanelLayout