import React, { useEffect, useState } from 'react'
import { Navbar, Container, Button, Spinner, Row, Col, Card, Form, FloatingLabel, Modal } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function AddNewOrganization() {

  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clicked, setClicked] = useState(false)

  const { checkIsExist, createNewStudent, userData, generatePassword } = useAuth();

  const navigate = useNavigate()

  const handleClick = async () => {
    setClicked(true)
    if (userData && name && surname && grade && username && password.length >= 6) {
          const isExist = await checkIsExist(username)
          if (!isExist) {
            try {
              await createNewStudent({
                name: name.trim(),
                surname: surname.trim(),
                email: `${username.trim()}@derecelgs.com`,
                studentNo: studentNo || null,
                classCode: classCode || null,
                grade: grade,
                username: username.trim(),
                password: password.trim(),
                role: "student",
                organizationId: userData.uid
              })
              setClicked(false)
              navigate("/kurum-paneli/ogrenci-listesi")
            } catch {
              setClicked(false)
              // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
              setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
              handleShow()
            }
          } else {
            let suffix = 2
            let newUsername = `${username}${suffix}`
            let bool = await checkIsExist(newUsername)

            while (bool) {
              suffix += 1
              newUsername = `${username}${suffix}`
              bool = await checkIsExist(newUsername)
            }

            await createNewStudent({
              name: name.trim(),
              surname: surname.trim(),
              email: `${newUsername.trim()}@derecelgs.com`,
              studentNo: studentNo || null,
              classCode: classCode || null,
              grade: grade,
              username: newUsername.trim(),
              password: password.trim(),
              role: "student",
              organizationId: userData.uid
            })

            setClicked(false)
            navigate("/kurum-paneli/ogrenci-listesi")
          }
    } else {
      setClicked(false)
      // alert("Lütfen tüm alanları tam ve doğru doldurun.")
      setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
      handleShow()
    }
  }

  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [studentNo, setStudentNo] = useState("")
  const [grade, setGrade] = useState("5")
  const [classCode, setClassCode] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const turkishToEnglish = (text) => {
    return text
      .replaceAll(/ğ/gim, "g")
      .replaceAll(/ü/gim, "u")
      .replaceAll(/ş/gim, "s")
      .replaceAll(/ı/gim, "i")
      .replaceAll(/ö/gim, "o")
      .replaceAll(/ç/gim, "c");
  }

  useEffect(() => {
    if (name && surname && userData) {
      const nameList = name.trim().split(" ")
      let abbrOfName = ""
      if (nameList.length > 1 || name.length + surname.length > 15) {
        nameList.forEach((name) => {
          if (name) {
            abbrOfName += name.split("")[0]
          }
        })
      } else {
        abbrOfName = nameList[0]
      }
      setUsername(userData.tag.toLowerCase() + turkishToEnglish(abbrOfName.toLocaleLowerCase('tr-TR')) + turkishToEnglish(surname.toLocaleLowerCase('tr-TR')))

      const pass = generatePassword()
      setPassword(pass)
    }
  }, [name, surname, userData, generatePassword])


  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Öğrenciler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/kurum-paneli/ogrenci-listesi' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Öğrenci Listesi</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Öğrenci Ekle</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
          <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Col lg={3} md={2} sm={1} />
            <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
              <Card >
                <Card.Body>
                  <Form onSubmit={e => { e.preventDefault(); }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput1"
                        label="Ad *"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Ad*" value={name} onChange={(e) => setName(e.target.value)} />

                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput1"
                        label="Soyad *"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Soyad *" value={surname} onChange={(e) => setSurname(e.target.value.trim())} />
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput1"
                        label="Öğrenci No"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Öğrenci No" value={studentNo} onChange={(e) => setStudentNo(e.target.value.trim().toUpperCase())} />
                      </FloatingLabel>
                    </Form.Group>

                    <FloatingLabel controlId="floatingSelect" label="Sınıf *">
                      <Form.Select className="mb-3" value={grade} onChange={(e) => setGrade(e.target.value)} aria-label="Default select example">
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </Form.Select>
                    </FloatingLabel>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput1"
                        label="Şube"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Şube" value={classCode} onChange={(e) => setClassCode(e.target.value.trim().toUpperCase())} />
                      </FloatingLabel>
                    </Form.Group>

                    <div style={{ margin: "0 0 12px 0", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: 0 }}>
                      <Form.Text style={{ marginTop: 0 }} className="text-muted">
                        Yıldız (*) işareti bulunan alanları doldurmak zorunludur.
                      </Form.Text>
                    </div>

                    {
                      clicked
                        ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                          <Spinner
                            style={{ marginRight: "6px" }}
                            as="span"
                            size="sm"
                            aria-hidden="true"
                          />
                        </Button>
                        : <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                          Öğrenci Ekle
                        </Button>
                    }

                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={2} sm={1} />
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>{modalTitle}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {modalError}
          </Modal.Body>

        </Modal>
      </Container>
    </>
  )
}


