import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Navbar, Container, Table } from 'react-bootstrap'
import { db } from '../../firebaseConfig';
import { useAuth } from '../../contexts/AuthContext';

export default function StudentListForTeacher() {

  const [students, setStudents] = useState([])

  const { userData } = useAuth();

  useEffect(() => {
    if (userData) {
      const getStudents = async () => {
        const students = [];
        const querySnapshot = await getDocs(query(collection(db, "students"), where("organizationId", "==", userData.organizationId), where("teachers", "array-contains", userData.uid)));
        querySnapshot.forEach((doc) => {
          students.push(doc.data())
        });
        students.sort((x, y) => {
          return y.createdAt - x.createdAt
        })
        setStudents(students)
      }

      getStudents()
    }
  }, [userData])

  const [fiveGrade, setFiveGrade] = useState(0)
  const [sixGrade, setSixGrade] = useState(0)
  const [sevenGrade, setSevenGrade] = useState(0)
  const [eightGrade, setEightGrade] = useState(0)


  useEffect(() => {
    if (students) {  
      students.forEach((student) => {
        switch (student.grade) {
          case "5":
            setFiveGrade((prev) => prev + 1)
            break;
          case "6":
            setSixGrade((prev) => prev + 1)
            break;
          case "7":
            setSevenGrade((prev) => prev + 1)
            break;
          case "8":
            setEightGrade((prev) => prev + 1)
            break;
          default:
            break;
        }
      })
    }
  }, [students])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Öğrenciler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Öğrenci Listesi</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        <Table  striped bordered responsive>
          <thead>
            <tr>
              <th style={{ width: "8%" }}>Toplam</th>
              <th style={{ width: "8%" }}>5. Sınıf</th>
              <th style={{ width: "8%" }}>6. Sınıf</th>
              <th style={{ width: "8%" }}>7. Sınıf</th>
              <th style={{ width: "8%" }}>8. Sınıf</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ verticalAlign: "middle" }}>
              <td>{students ? students.length : 0}</td>
              <td>{fiveGrade}</td>
              <td>{sixGrade}</td>
              <td>{sevenGrade}</td>
              <td>{eightGrade}</td>
            </tr>

          </tbody>
        </Table>

        {
          students
            ? students.length > 0
              ? <Table style={{ marginTop: '8px' }} striped bordered responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{ width: "20%" }}>Ad</th>
                    <th style={{ width: "20%" }}>Soyad</th>
                    <th style={{ width: "20%" }}>Öğrenci No</th>
                    <th style={{ width: "20%" }}>Sınıf & Şube</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    students && students.map((student, index) => {
                      return (
                        <tr key={index} style={{ verticalAlign: "middle" }}>
                          <td>{index + 1}</td>
                          <td>{student.name}</td>
                          <td>{student.surname}</td>
                          <td>{student.studentNo}</td>
                          <td>{student.grade} {student.classCode ? `- ${student.classCode}` : null}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              : null
            : null
        }


      </Container>
    </>
  )
}
