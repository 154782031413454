import React from 'react'
import { Button } from 'react-bootstrap';

export default function QuestionRow({ question, index, handleShow, setSelectedQuestion, handleShowDialog }) {

  const date = new Date(question.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })

  const askDelete = async () => {
    await setSelectedQuestion(question)
    handleShowDialog()
  }

  return (
    <tr key={index} style={{ verticalAlign: "middle" }}>
      <td>{index + 1}</td>
      <td>
        <a href={question.image} target='_blank' rel="noreferrer">
          <img src={question.image} alt="" style={{ width: "100%", height: "200px", objectFit: "contain" }} />
        </a>
      </td>
      <td>{date}</td>
      <td>{question.status === "pending" ? "Çözülmeyi Bekliyor" : "Çözüldü"}</td>
      <td style={{ textAlign: "center" }}>
        <Button variant={question.status === "pending" ? "danger" : "primary"} onClick={() => question.status === "pending" ? askDelete() : handleShow(question)} size="sm">{question.status === "pending" ? "Soruyu Kaldır" : "Çözümü Göster"}</Button>
      </td>
    </tr>
  )
}
