import React from 'react'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function QuestionRowForTeacher({ question, index, handleShow, setSelectedQuestion, handleShowDialog }) {

  const date = new Date(question.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })

  // const askDelete = async () => {
  //   await setSelectedQuestion(question)
  //   handleShowDialog()
  // }

  const navigate = useNavigate()

  return (
    <tr key={index} style={{ verticalAlign: "middle" }}>
      <td>{index + 1}</td>
      <td>
        <a href={question.image} target='_blank' rel="noreferrer">
          <img src={question.image} alt="" style={{ width: "100%", height: "200px", objectFit: "contain" }} />
        </a>
      </td>
      <td>{date}</td>
      <td>{question.status === "pending" ? "Çözülmeyi Bekliyor" : "Çözüldü"}</td>
      <td style={{ textAlign: "center" }}>
        {
          <Button variant={"primary"} onClick={() => question.status === "solved" ? handleShow(question) : navigate("/ogretmen-paneli/cozulemeyen-sorular/cozum-ekle/" + question.uid)} size="sm">{question.status === "pending" ? "Çözüm Ekle" : "Görüntüle"}</Button>
        }
      </td>
    </tr>
  )
}
