import React, { useEffect, useState } from 'react'
import { Navbar, Container, Table, Button, Accordion, Modal, Row, Col, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebaseConfig"
import GradeExamsForStudent from "../student/GradeExamsForStudent"
import BranchExamsForStudent from "../student/BranchExamsForStudent"
import LGSExamsForStudent from "./LGSExamsForStudent"
import { useAuth } from '../../contexts/AuthContext';

export default function ExamsForStudent() {
  const [clicked, setClicked] = useState(false)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (selected) => {
    setSelectedExam(selected)
    setShow(true)
  };

  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  const { userData } = useAuth()

  const deleteExam = async () => {
    setClicked(true)
    if (selectedExam && userData) {
      await deleteDoc(doc(db, "exams", selectedExam.uid));

      const modified = exams.filter((exam) => exam.uid !== selectedExam.uid)

      setExams(modified)

      if (selectedExam.type === "lgs") {
        const modifiedLogs = await userData.lgsExamsLogs.filter((log) => log.uid !== selectedExam.uid)
        const logRef = doc(db, "students", userData.uid);
        await updateDoc(logRef, {
          lgsExamsLogs: modifiedLogs
        });
      }

      setClicked(false)
    }
    handleClose()
  }

  const navigate = useNavigate();

  const [selectedExam, setSelectedExam] = useState();

  const [exams, setExams] = useState([])

  const [totalLGSExams, setTotalLGSExams] = useState(0)
  const [totalGradeExams, setTotalGradeExams] = useState(0)
  const [totalBranchExams, setTotalBranchExams] = useState(0)

  const [lgsExams, setLGSExams] = useState([])
  const [gradeExams, setGradeExams] = useState([])
  const [branchExams, setBranchExams] = useState([])

  useEffect(() => {
    if (userData) {
      const getExams = async () => {
        const exams = [];
        const querySnapshot = await getDocs(query(collection(db, "exams"), where("studentId", "==", userData.uid)));
        querySnapshot.forEach((doc) => {
          exams.push(doc.data())
        });
        exams.sort((x, y) => {
          return y.createdAt - x.createdAt
        })
        setExams(exams)
      }

      getExams()
    }
  }, [userData])

  useEffect(() => {
    const calculateTotalExams = async () => {
      let totalLGSExams = 0
      let totalGradeExams = 0
      let totalBranchExams = 0


      exams.forEach((exam) => {
        if (exam.type === "lgs") {
          totalLGSExams += 1
        } else if (exam.type === "grade") {
          totalGradeExams += 1
        } else if (exam.type === "branch") {
          totalBranchExams += 1
        } 
      });

      setTotalLGSExams(totalLGSExams)
      setTotalGradeExams(totalGradeExams)
      setTotalBranchExams(totalBranchExams)
    }

    if (exams) {
      calculateTotalExams()
    }
  }, [exams])

  useEffect(() => {
    const filterExams = async () => {
      let lgsExams = []
      let gradeExams = []
      let branchExams = []

      exams.forEach((exam) => {
        if (exam.type === "lgs") {
          lgsExams.push(exam)
        } else if (exam.type === "grade") {
          gradeExams.push(exam)
        } else if (exam.type === "branch") {
          branchExams.push(exam)
        }
      });

      setLGSExams(lgsExams)
      setGradeExams(gradeExams)
      setBranchExams(branchExams)
    }


    if (exams) {
      filterExams()
    }
  }, [exams])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Denemeler</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      {
        exams &&
        <>
          <Container style={{ marginBottom: "24px" }}>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Toplam</th>
                  <th style={{ width: "20%" }}>Sınıf</th>
                  <th style={{ width: "20%" }}>Branş</th>
                  <th style={{ width: "20%" }}>LGS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ verticalAlign: "middle" }}>
                  <td>{exams ? exams.length : 0}</td>
                  <td>{totalGradeExams}</td>
                  <td>{totalBranchExams}</td>
                  <td>{totalLGSExams}</td>
                  <td style={{ textAlign: "center" }}>
                    <Button onClick={() => navigate("deneme-ekle")} size="sm" >Deneme Ekle</Button>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Accordion style={{ marginTop: "8px" }} alwaysOpen>
              {
                gradeExams ?
                  gradeExams.length > 0 ?
                    <Accordion.Item eventKey={0}>
                      <Accordion.Header>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <p style={{ padding: 0, margin: 0 }}>Sınıf Denemeleri</p>
                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>{totalGradeExams}</p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ backgroundColor: "#f8f9fa6e" }}>
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th style={{ width: "9.5%" }}>Deneme</th>
                              <th style={{ width: "9.5%" }}>Tür</th>
                              <th style={{ width: "9.5%" }}>Tarih</th>
                              <th style={{ width: "9.5%" }}>Soru Sayısı</th>
                              <th style={{ width: "9.5%" }}>Türkçe Neti</th>
                              <th style={{ width: "9.5%" }}>Sosyal Neti</th>
                              <th style={{ width: "9.5%" }}>Din Neti</th>
                              <th style={{ width: "9.5%" }}>İngilizce Neti</th>
                              <th style={{ width: "9.5%" }}>Mat. Neti</th>
                              <th style={{ width: "9.5%" }}>Fen Neti</th>
                              <th style={{ width: "9.5%" }}>Toplam Net</th>
                              <th ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              gradeExams.map((exam, index) => {
                                return (
                                  <GradeExamsForStudent key={index} exam={exam} index={index} handleShow={handleShow} />
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    : null
                  : null
              }
              {
                branchExams ?
                  branchExams.length > 0 ?
                    <Accordion.Item eventKey={1}>
                      <Accordion.Header>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <p style={{ padding: 0, margin: 0 }}>Branş Denemeleri</p>
                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>{totalBranchExams}</p>

                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ backgroundColor: "#f8f9fa6e" }} >
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th style={{ width: "14%" }}>Deneme</th>
                              <th style={{ width: "9.5%" }}>Tür</th>
                              <th style={{ width: "9.5%" }}>Ders</th>
                              <th style={{ width: "9.5%" }}>Tarih</th>
                              <th style={{ width: "9.5%" }}>Soru Sayısı</th>
                              <th style={{ width: "8%" }}>Doğru</th>
                              <th style={{ width: "8%" }}>Yanlış</th>
                              <th style={{ width: "8%" }}>Boş</th>
                              <th style={{ width: "8%" }}>Net</th>
                              <th ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              branchExams.map((exam, index) => {
                                return (
                                  <BranchExamsForStudent key={index} exam={exam} index={index} handleShow={handleShow} />
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    : null
                  : null
              }
              {
                lgsExams ?
                  lgsExams.length > 0 ?
                    <Accordion.Item eventKey={2}>
                      <Accordion.Header>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <p style={{ padding: 0, margin: 0 }}>LGS Denemeleri</p>
                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>{totalLGSExams}</p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ backgroundColor: "#f8f9fa6e" }} >
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th style={{ width: "9.5%" }}>Deneme</th>
                              <th style={{ width: "9.5%" }}>Tür</th>
                              <th style={{ width: "9.5%" }}>Tarih</th>
                              <th style={{ width: "9.5%" }}>Soru Sayısı</th>
                              <th style={{ width: "9.5%" }}>Türkçe Neti</th>
                              <th style={{ width: "9.5%" }}>Sosyal Neti</th>
                              <th style={{ width: "9.5%" }}>Din Neti</th>
                              <th style={{ width: "9.5%" }}>İngilizce Neti</th>
                              <th style={{ width: "9.5%" }}>Mat. Neti</th>
                              <th style={{ width: "9.5%" }}>Fen Neti</th>
                              <th style={{ width: "9.5%" }}>Toplam Net</th>
                              <th ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              lgsExams.map((exam, index) => {
                                return (
                                  <LGSExamsForStudent key={index} exam={exam} index={index} handleShow={handleShow} />
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    : null
                  : null
              }
            </Accordion>

            <Modal
              show={show && !showDialog}
              onHide={handleClose}
              keyboard={false}
              backdrop="static"
            >
              {
                (selectedExam && selectedExam.type === "lgs") &&
                <>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.25rem", }}>{selectedExam && selectedExam.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Deneme Türü: <span style={{ fontWeight: "400" }}>LGS</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih: <span style={{ fontWeight: "400" }}>{new Date(selectedExam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.turkishTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.turkishFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Boş: <span style={{ fontWeight: "400" }}>{selectedExam.turkishEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Net: <span style={{ fontWeight: "400" }}>{selectedExam.turkishNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.socialTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.socialFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Boş: <span style={{ fontWeight: "400" }}>{selectedExam.socialEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Net: <span style={{ fontWeight: "400" }}>{selectedExam.socialNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.socialTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.socialFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Boş: <span style={{ fontWeight: "400" }}>{selectedExam.socialEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Net: <span style={{ fontWeight: "400" }}>{selectedExam.socialNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>İngilizce Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.socialTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>İngilizce Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.socialFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>İngilizce Boş: <span style={{ fontWeight: "400" }}>{selectedExam.socialEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>İngilizce Net: <span style={{ fontWeight: "400" }}>{selectedExam.socialNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.mathTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.mathFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Boş: <span style={{ fontWeight: "400" }}>{selectedExam.mathEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Net: <span style={{ fontWeight: "400" }}>{selectedExam.mathNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.scienceTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.scienceFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Boş: <span style={{ fontWeight: "400" }}>{selectedExam.scienceEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Net: <span style={{ fontWeight: "400" }}>{selectedExam.scienceNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row >
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Soru: <span style={{ fontWeight: "400" }}>{selectedExam.questionNumber}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Net: <span style={{ fontWeight: "400" }}>{selectedExam.totalNet}</span></p>
                      </Col>
                    </Row>


                  </Modal.Body>

                </>
              }
              {
                (selectedExam && selectedExam.type === "grade") &&
                <>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.25rem", }}>{selectedExam && selectedExam.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Deneme Türü: <span style={{ fontWeight: "400" }}>Sınıf ({selectedExam.grade})</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih: <span style={{ fontWeight: "400" }}>{new Date(selectedExam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.turkishTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.turkishFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Boş: <span style={{ fontWeight: "400" }}>{selectedExam.turkishEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Net: <span style={{ fontWeight: "400" }}>{selectedExam.turkishNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.socialTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.socialFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Boş: <span style={{ fontWeight: "400" }}>{selectedExam.socialEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Net: <span style={{ fontWeight: "400" }}>{selectedExam.socialNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.socialTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.socialFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Boş: <span style={{ fontWeight: "400" }}>{selectedExam.socialEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Net: <span style={{ fontWeight: "400" }}>{selectedExam.socialNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>İngilizce Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.socialTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>İngilizce Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.socialFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>İngilizce Boş: <span style={{ fontWeight: "400" }}>{selectedExam.socialEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>İngilizce Net: <span style={{ fontWeight: "400" }}>{selectedExam.socialNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.mathTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.mathFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Boş: <span style={{ fontWeight: "400" }}>{selectedExam.mathEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Net: <span style={{ fontWeight: "400" }}>{selectedExam.mathNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.scienceTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.scienceFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Boş: <span style={{ fontWeight: "400" }}>{selectedExam.scienceEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Net: <span style={{ fontWeight: "400" }}>{selectedExam.scienceNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row >
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Soru: <span style={{ fontWeight: "400" }}>{selectedExam.questionNumber}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Net: <span style={{ fontWeight: "400" }}>{selectedExam.totalNet}</span></p>
                      </Col>
                    </Row>
                  </Modal.Body>

                </>
              }
              {
                (selectedExam && selectedExam.type === "branch") &&
                <>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.25rem", }}>{selectedExam && selectedExam.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Deneme Türü: <span style={{ fontWeight: "400" }}>Branş <br />({selectedExam.examType} {selectedExam.lesson})</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih: <span style={{ fontWeight: "400" }}>{new Date(selectedExam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.generalTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.generalFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Boş: <span style={{ fontWeight: "400" }}>{selectedExam.generalEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Net: <span style={{ fontWeight: "400" }}>{selectedExam.totalNet}</span></p>
                      </Col>
                    </Row>
                  </Modal.Body>
                </>
              }

              <Modal.Footer>
                {
                  clicked
                    ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                      <Spinner
                        style={{ marginRight: "6px" }}
                        as="span"
                        size="sm"
                        aria-hidden="true"
                      />
                    </Button>
                    : <>
                      <Button onClick={handleShowDialog} disabled={clicked} style={{ width: "100%" }} variant="outline-danger" >
                        Denemeyi Sil
                      </Button>
                    </>
                }
              </Modal.Footer>
            </Modal>

            <Modal
              show={showDialog}
              onHide={handleCloseDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                Geri dönüşü olmayan bir işlem yapmak üzeresiniz. Bu denemeyi tüm verileri ile birlikte silmek istediğinizden emin misiniz?
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => {
                  deleteExam()
                  handleCloseDialog()
                }} variant="danger">Denemeyi Sil</Button>
              </Modal.Footer>
            </Modal>

          </Container>
        </>
      }
    </>
  )
}
