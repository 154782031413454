import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Home from "../pages/general/Home"
import NotFound from "../pages/general/NotFound"
import Login from '../pages/login/Login'
import OrganizationLogin from '../pages/login/OrganizationLogin'
import TeacherLogin from '../pages/login/TeacherLogin'
import ParentLogin from '../pages/login/ParentLogin'
import OrganizationPanelLayout from '../pages/organization/OrganizationPanelLayout'
import TeacherPanelLayout from '../pages/teacher/TeacherPanelLayout'
import ParentPanelLayout from '../pages/parent/ParentPanelLayout'
import ParentPanelOverview from '../pages/parent/ParentPanelOverview'
import OrganizationPanelOverview from '../pages/organization/OrganizationPanelOverview'
import ProtectedRoute from './ProtectedRoute'
import TeacherPanelOverview from '../pages/teacher/TeacherPanelOverview'
import StudentList from '../pages/organization/StudentList'
import AddNewStudent from '../pages/organization/AddNewStudent'
import SubjectTracking from '../pages/organization/SubjectTracking'
import StudyTime from '../pages/organization/StudyTime'
import SolvedQuestions from '../pages/organization/SolvedQuestions'
import Exams from '../pages/organization/Exams'
import TeacherList from '../pages/organization/TeacherList'
import AddNewTeacher from '../pages/organization/AddNewTeacher'
import AdminPanelLayout from '../pages/admin/AdminPanelLayout'
import AdminPanelOverview from '../pages/admin/AdminPanelOverview'
import AdminLogin from '../pages/login/AdminLogin'
import OrganizationList from '../pages/admin/OrganizationList'
import AddNewOrganization from '../pages/admin/AddNewOrganization'
import EditOrganization from '../pages/admin/EditOrganization'
import EditStudent from '../pages/organization/EditStudent'
import EditTeacher from '../pages/organization/EditTeacher'
import ParentList from '../pages/organization/ParentList'
import StudentPanelLayout from '../pages/student/StudentPanelLayout'
import StudentPanelOverview from '../pages/student/StudentPanelOverview'
import StudentLogin from '../pages/login/StudentLogin'
import StudentListForTeacher from '../pages/teacher/StudentListForTeacher'
import SubjectTrackingForTeacher from "../pages/teacher/SubjectTrackingForTeacher"
import StudyTimeForTeacher from '../pages/teacher/StudyTimeForTeacher'
import SolvedQuestionsForTeacher from '../pages/teacher/SolvedQuestionsForTeacher'
import ExamsForTeacher from '../pages/teacher/ExamsForTeacher'
import UnsolvableQuestionsForTeacher from '../pages/teacher/UnsolvableQuestionsForTeacher'
import SubjectTrackingForStudent from '../pages/student/SubjectTrackingForStudent'
import StudyTimeForStudent from '../pages/student/StudyTimeForStudent'
import SolvedQuestionsForStudent from '../pages/student/SolvedQuestionsForStudent'
import ExamsForStudent from '../pages/student/ExamsForStudent'
import UnsolvableQuestionsForStudent from '../pages/student/UnsolvableQuestionsForStudent'
import TeacherListForStudent from '../pages/student/TeacherListForStudent'
import AddNewExam from '../pages/student/AddNewExam'
import WhoStudyWhat from '../pages/student/WhoStudyWhat'
import AddNewQuestion from '../pages/student/AddNewQuestion'
import SubjectTrackingReport from '../pages/organization/SubjectTrackingReport'
import StudyTimeReport from '../pages/organization/StudyTimeReport'
import SolvedQuestionsReport from '../pages/organization/SolvedQuestionsReport'
import ExamsReport from '../pages/organization/ExamsReport'
import SubjectTrackingReportForTeacher from '../pages/teacher/SubjectTrackingReportForTeacher'
import StudyTimeReportForTeacher from '../pages/teacher/StudyTimeReportForTeacher'
import SolvedQuestionsReportForTeacher from '../pages/teacher/SolvedQuestionsReportForTeacher'
import ExamsReportForTeacher from '../pages/teacher/ExamsReportForTeacher'
import UnsolvableQuestionsReportForTeacher from '../pages/teacher/UnsolvableQuestionsReportForTeacher'
import AddNewSolution from '../pages/teacher/AddNewSolution'
import SubjectTrackingForParent from '../pages/parent/SubjectTrackingForParent'
import StudyTimeForParent from '../pages/parent/StudyTimeForParent'
import SolvedQuestionsForParent from '../pages/parent/SolvedQuestionsForParent'
import ExamsForParent from '../pages/parent/ExamsForParent'
import StudentAnalysis from '../pages/organization/StudentAnalysis'
import StudentAnalysisForTeacher from '../pages/teacher/StudentAnalysisForTeacher'
import LoginProtectedRoute from './LoginProtectedRoute'

export default function Routers() {
  return (
    <Routes>
      {/* general */}
      <Route path='/' element={<Home />} />
      <Route path='/anasayfa' element={<Navigate to="/" />} />
      <Route path='/giris' element={
        <LoginProtectedRoute>
          <Login />
        </LoginProtectedRoute>
      } />
      <Route path='/kurum-girisi' element={
        <LoginProtectedRoute>
          <OrganizationLogin />
        </LoginProtectedRoute>
      } />
      <Route path='/ogretmen-girisi' element={
        <LoginProtectedRoute>
          <TeacherLogin />
        </LoginProtectedRoute>
      } />
      <Route path='/veli-girisi' element={
        <LoginProtectedRoute>
          <ParentLogin />
        </LoginProtectedRoute>
      } />
      <Route path='/admin-girisi' element={
        <LoginProtectedRoute>
          <AdminLogin />
        </LoginProtectedRoute>
      } />
      <Route path='/ogrenci-girisi' element={
        <LoginProtectedRoute>
          <StudentLogin />
        </LoginProtectedRoute>
      } />

      {/* organization */}
      <Route
        path='/kurum-paneli'
        element={
          <ProtectedRoute role={"organization"}>
            <OrganizationPanelLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<OrganizationPanelOverview />} />
        <Route path='ogrenci-analizi/:id' element={<StudentAnalysis />} />
        <Route path='ogrenci-listesi' element={<StudentList />} />
        <Route path='ogrenci-listesi/ogrenci-ekle' element={<AddNewStudent />} />
        <Route path='ogrenci-listesi/ogrenci-duzenle/:id' element={<EditStudent />} />


        <Route path='konu-takibi' element={<SubjectTracking />} />
        <Route path='konu-takibi/:id' element={<SubjectTrackingReport />} />
        <Route path='ders-calisma-suresi' element={<StudyTime />} />
        <Route path='ders-calisma-suresi/:id' element={<StudyTimeReport />} />
        <Route path='cozulen-soru-sayisi' element={<SolvedQuestions />} />
        <Route path='cozulen-soru-sayisi/:id' element={<SolvedQuestionsReport />} />
        <Route path='denemeler' element={<Exams />} />
        <Route path='denemeler/:id' element={<ExamsReport />} />
        <Route path='ogretmen-listesi' element={<TeacherList />} />
        <Route path='ogretmen-listesi/ogretmen-ekle' element={<AddNewTeacher />} />
        <Route path='ogretmen-listesi/ogretmen-duzenle/:id' element={<EditTeacher />} />

        <Route path='veli-listesi' element={<ParentList />} />
      </Route>

      {/* teacher */}
      <Route
        path='/ogretmen-paneli'
        element={
          <ProtectedRoute role={"teacher"}>
            <TeacherPanelLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<TeacherPanelOverview />} />
        <Route path='ogrenci-analizi/:id' element={<StudentAnalysisForTeacher />} />
        <Route path='ogrenci-listesi' element={<StudentListForTeacher />} />
        <Route path='konu-takibi' element={<SubjectTrackingForTeacher />} />
        <Route path='konu-takibi/:id' element={<SubjectTrackingReportForTeacher />} />
        <Route path='ders-calisma-suresi' element={<StudyTimeForTeacher />} />
        <Route path='ders-calisma-suresi/:id' element={<StudyTimeReportForTeacher />} />
        <Route path='cozulen-soru-sayisi' element={<SolvedQuestionsForTeacher />} />
        <Route path='cozulen-soru-sayisi/:id' element={<SolvedQuestionsReportForTeacher />} />
        <Route path='denemeler' element={<ExamsForTeacher />} />
        <Route path='denemeler/:id' element={<ExamsReportForTeacher />} />
        <Route path='cozulemeyen-sorular' element={<UnsolvableQuestionsForTeacher />} />
        <Route path='cozulemeyen-sorular/cozum-ekle/:id' element={<AddNewSolution />} />
        <Route path='cozulemeyen-sorular/:id' element={<UnsolvableQuestionsReportForTeacher />} />


      </Route>

      {/* parent */}
      <Route
        path='/veli-paneli'
        element={
          <ProtectedRoute role={"parent"}>
            <ParentPanelLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<ParentPanelOverview />} />
        <Route path='konu-takibi' element={<SubjectTrackingForParent />} />
        <Route path='ders-calisma-suresi' element={<StudyTimeForParent />} />
        <Route path='cozulen-soru-sayisi' element={<SolvedQuestionsForParent />} />
        <Route path='denemeler' element={<ExamsForParent />} />
      </Route>

      {/* admin */}
      <Route
        path='/admin-paneli'
        element={
          <ProtectedRoute role={"admin"}>
            <AdminPanelLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<AdminPanelOverview />} />
        <Route path='kurum-listesi' element={<OrganizationList />} />
        <Route path='kurum-listesi/kurum-ekle' element={<AddNewOrganization />} />
        <Route path='kurum-listesi/kurum-duzenle/:id' element={<EditOrganization />} />
      </Route>

      {/* student */}
      <Route
        path='/ogrenci-paneli'
        element={
          <ProtectedRoute role={"student"}>
            <StudentPanelLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<StudentPanelOverview />} />
        <Route path='konu-takibi' element={<SubjectTrackingForStudent />} />
        <Route path='ders-calisma-suresi' element={<StudyTimeForStudent />} />
        <Route path='cozulen-soru-sayisi' element={<SolvedQuestionsForStudent />} />
        <Route path='denemeler' element={<ExamsForStudent />} />
        <Route path='denemeler/deneme-ekle' element={<AddNewExam />} />
        <Route path='cozulemeyen-sorular' element={<UnsolvableQuestionsForStudent />} />
        <Route path='cozulemeyen-sorular/soru-ekle' element={<AddNewQuestion />} />
        <Route path='kim-ne-calisiyor' element={<WhoStudyWhat />} />
        <Route path='ogretmen-listesi' element={<TeacherListForStudent />} />
      </Route>

      {/* missing */}
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}