import React, { useState, useEffect } from 'react'
import { Navbar, Container, Button, Spinner, Row, Col, Card, Form, FloatingLabel, Modal } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

export default function EditStudent() {

  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  const [clicked, setClicked] = useState(false)

  const { updateStudentData, deleteStudent, userData } = useAuth();

  const navigate = useNavigate()

  const deleteHandle = async (student) => {
    setClicked(true)
    await deleteStudent(student)
    setClicked(false)
    navigate("/kurum-paneli/ogrenci-listesi")
  }

  const handleClick = async () => {
    setClicked(true)
    if (oldData && name && surname && grade) {
              try {
                await updateStudentData(params.id, {
                  grade: grade,
                  studentNo: studentNo || null,
                  classCode: classCode || null
                })
                setClicked(false)
                navigate("/kurum-paneli/ogrenci-listesi")
              } catch {
                setClicked(false)
                // alert("Güncelleme işlemi yapılırken bir hata meydana geldi.")
                setModalError("Güncelleme işlemi yapılırken bir hata meydana geldi.")
                handleShow()
              }
    } else {
      setClicked(false)
      // alert("Lütfen tüm alanları tam ve doğru doldurun.")
      setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
      handleShow()
    }
  }

  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [grade, setGrade] = useState("5")


  const [studentNo, setStudentNo] = useState("")
  const [classCode, setClassCode] = useState("")

  const [oldData, setOldData] = useState()

  const params = useParams();

  useEffect(() => {
    const getStudentData = async () => {
      const uid = params.id
      const docRef = doc(db, "students", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const dataForStudent = docSnap.data()
        if (dataForStudent.organizationId === userData.uid) {
          setOldData(docSnap.data())
        } else {
          navigate("/kurum-paneli/ogrenci-listesi")
        }
      } else {
        navigate("/kurum-paneli/ogrenci-listesi")
      }
    }
    getStudentData()
  }, [params.id, navigate, userData])

  useEffect(() => {
    if (oldData) {
      setName(oldData.name)
      setSurname(oldData.surname)
      setGrade(oldData.grade)
      setStudentNo(oldData.studentNo || null)
      setClassCode(oldData.classCode || null)
    }
  }, [oldData])


  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Öğrenciler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/kurum-paneli/ogrenci-listesi' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Öğrenci Listesi</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Öğrenci Düzenle</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        {
          oldData && <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
            <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Col lg={3} md={2} sm={1} />
              <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
                <Card >
                  <Card.Body>
                    <Form onSubmit={e => { e.preventDefault(); }}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput1"
                          label="Ad"
                          className="mb-3"
                        >
                          <Form.Control disabled type='text' placeholder="Ad" value={name} onChange={(e) => setName(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput1"
                          label="Soyad"
                          className="mb-3"
                        >
                          <Form.Control disabled type='text' placeholder="Soyad" value={surname} onChange={(e) => setSurname(e.target.value.trim())} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput1"
                          label="Öğrenci No"
                          className="mb-3"
                        >
                          <Form.Control type='text' placeholder="Öğrenci No" value={studentNo} onChange={(e) => setStudentNo(e.target.value.trim().toUpperCase())} />
                        </FloatingLabel>
                      </Form.Group>

                      <FloatingLabel controlId="floatingSelect" label="Sınıf *">
                        <Form.Select className="mb-3" value={grade} onChange={(e) => setGrade(e.target.value)}>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </Form.Select>
                      </FloatingLabel>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput1"
                          label="Şube"
                          className="mb-3"
                        >
                          <Form.Control type='text' placeholder="Şube" value={classCode} onChange={(e) => setClassCode(e.target.value.trim().toUpperCase())} />
                        </FloatingLabel>
                      </Form.Group>

                      <div style={{ margin: "0 0 12px 0", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: 0 }}>
                        <Form.Text style={{ marginTop: 0 }} className="text-muted">
                          Yıldız (*) işareti bulunan alanları doldurmak zorunludur.
                        </Form.Text>
                      </div>


                      {
                        clicked
                          ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                            <Spinner
                              style={{ marginRight: "6px" }}
                              as="span"
                              size="sm"
                              aria-hidden="true"
                            />
                          </Button>
                          : <>
                            <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                              Kaydet
                            </Button>
                            <Button onClick={() => handleShowDialog()} disabled={clicked || !oldData ? true : false} style={{ marginTop: "12px", width: "100%" }} variant="outline-danger" >
                              Öğrenciyi Sil
                            </Button>
                          </>
                      }
                    </Form>
                  </Card.Body>
                </Card>

              </Col>
              <Col lg={3} md={2} sm={1} />
            </Row>
          </Container>
        }

        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>{modalTitle}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {modalError}
          </Modal.Body>

        </Modal>

        <Modal
          show={showDialog}
          onHide={handleCloseDialog}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            Geri dönüşü olmayan bir işlem yapmak üzeresiniz. Öğrenciyi tüm verileri ile birlikte silmek istediğinizden emin misiniz?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => {
              deleteHandle(oldData)
              handleCloseDialog()
            }} variant="danger">Öğrenciyi Sil</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  )
}
