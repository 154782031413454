import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const LoginProtectedRoute = ({ role, children }) => {
  const { currentUser } = useAuth()

  if (!currentUser) {
    return children;
  }
  else {
    return <Navigate to="/" replace />;
  }

};

export default LoginProtectedRoute;