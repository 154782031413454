import React, { useEffect, useState } from 'react'
import { Navbar, Container, Col, Row, Alert } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebaseConfig';


export default function WhoStudyWhat() {

  const { realTimeUserData } = useAuth()

  const [lastActivites, setLastActivities] = useState([])

  const { userData } = useAuth()

  useEffect(() => {
    const getLastActivities = async () => {
      const lastActivitiesList = [];

      const querySnapshot = await getDocs(query(collection(db, "lastActivities"), where("organizationId", "==", userData.organizationId)));

      querySnapshot.forEach((doc) => {
        lastActivitiesList.push(doc.data())
      });
      lastActivitiesList.sort((x, y) => {
        return y.createdAt - x.createdAt
      })

      if (lastActivitiesList.length > 25) {
        const willClear = lastActivitiesList.slice(0, lastActivitiesList.length - 25)
        willClear.forEach(async (activity) => {
          await deleteDoc(doc(db, "lastActivities", activity.uid));
        })
      }

      setLastActivities(lastActivitiesList)
    }

    getLastActivities()
  }, [userData])

  const colors = [
    'primary',
    'success',
    'danger',
    'warning',
    'info',
  ]

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Kim Ne Çalışıyor?</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      {
        (realTimeUserData) &&
        <Container style={{ marginBottom: "24px" }}>
          <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
            <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Col lg={3} md={2} sm={1} />
              <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
                {
                  lastActivites &&
                  lastActivites.map((activity, index) => {

                    const time = new Date(activity.createdAt).toLocaleTimeString("tr")
                    const hours = time.split(":")[0]
                    const minutes = time.split(":")[1]

                    return (
                      <Alert key={index} variant={colors[index % 5]}>
                        {
                          `Öğrencilerden biri ${activity.message} (${hours}:${minutes})`
                        }
                      </Alert>
                    )
                  })
                }

              </Col>
              <Col lg={3} md={2} sm={1} />
            </Row>
          </Container>

        </Container>
      }
    </>
  )
}
