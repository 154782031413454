import React, { useEffect, useState } from 'react'
import { Row, Nav, Container, Navbar, NavDropdown } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate, Outlet } from 'react-router-dom'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../firebaseConfig'

function AdminPanelLayout() {

  const { logOut, userData } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    if (userData) {
      if (userData.role !== "admin") {
        navigate("/")
      }
    }
  }, [userData, navigate])

  const [admins, setAdmins] = useState(0)

  const getAdmins = async () => {
    let adminCount = 0;

    const querySnapshot = await getDocs(collection(db, "admins"));

    querySnapshot.forEach((doc) => {
      adminCount += 1
    });

    setAdmins(adminCount)
  }

  useEffect(() => {
    getAdmins()
  }, [])

  return (
    <>
      {
        (userData && userData.role === "admin") &&
        <Container fluid>
          <Row>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary border-bottom">
              <Container>
                <Navbar.Brand href='/admin-paneli'>Admin Paneli</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <NavDropdown title="Kurumlar" id="collasible-nav-dropdown">
                      <NavDropdown.Item onClick={() => navigate("kurum-listesi")}>Kurum Listesi</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Nav>
                    <NavDropdown title={`${userData.name} (${userData.order}/${admins})`} id="collasible-nav-dropdown">
                      <NavDropdown.Item onClick={() => logOut()}>Çıkış Yap</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Row>
          <Row style={{ padding: 0 }}>
            <Container style={{ padding: 0 }}>
              <Outlet />
            </Container>
          </Row>
        </Container>
      }
    </>
  )
}

export default AdminPanelLayout