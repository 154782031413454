import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Navbar, Container, Accordion, Col, Row, Form } from 'react-bootstrap'
import { db } from '../../firebaseConfig';
import lessons from "../../datas/lessonsForLgs.json"
import subjects from "../../datas/subjectsForLgs.json"
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export default function StudyTimeReportForTeacher() {

  const { userData } = useAuth();

  const params = useParams();
  const navigate = useNavigate()

  const [studentData, setStudentData] = useState()

  useEffect(() => {
    const getStudentData = async () => {
      const uid = params.id
      const docRef = doc(db, "students", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const dataForStudent = docSnap.data()
        if (dataForStudent.teachers && dataForStudent.teachers.includes(userData.uid)) {
          setStudentData(docSnap.data())

        } else {
          navigate("/ogretmen-paneli/ders-calisma-suresi")

        }
      } else {
        navigate("/ogretmen-paneli/ders-calisma-suresi")
      }
    }
    getStudentData()
  }, [params.id, navigate, userData])

  return (
    <>
      <Navbar style={{ overflowX: "hidden"}} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Öğrenciler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/ogretmen-paneli/ders-calisma-suresi/' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Ders Çalışma Süresi</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              {/* tıklanabilir */}
              
              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Öğrenci Raporu</p>
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      {
        studentData &&
        <Navbar style={{ marginBottom: "24px", overflowX: "hidden" }} expand="lg" className="bg-white border-bottom">
          <Container>
            <Navbar.Brand>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "400" }}><span style={{ color: "#000000", fontWeight: "500"}}>{`${studentData.name} ${studentData.surname}`}</span> {`${studentData.studentNo ? `(${studentData.studentNo})` : ""} ${studentData.classCode ? `(${studentData.grade}-${studentData.classCode})` : `(${studentData.grade})`} ${studentData.lang ? `(${studentData.domain}-${studentData.lang})` : studentData.domain ? `(${studentData.domain})` : ""}`}</p>
              </div>
            </Navbar.Brand>
          </Container>
        </Navbar>
      }
      {
        (studentData) &&
        <Container style={{ marginBottom: "24px" }}>
        <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
          <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Col lg={3} md={2} sm={1} />
            <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
              <Accordion alwaysOpen>
                {
                  ["5", "6", "7", "8"].map((grade, index) => {

                    let totalMinutes = 0

                    lessons.forEach((lesson) => {
                      subjects.forEach((subject) => {
                        if (subject.lessonId === lesson.uid && subject.grade === grade) {
                          const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                          if (studentData.studyTime) {
                            if (studentData.studyTime[codingKey]) {
                              totalMinutes += Number(studentData.studyTime[codingKey])
                            }
                          }
                        }
                      })
                    })

                    let hours = ~~(totalMinutes / 60)
                    let minutes = totalMinutes - (hours * 60)

                    if (hours < 10) hours = `0${hours}`
                    if (minutes < 10) minutes = `0${minutes}`

                    return (
                      <Accordion.Item key={index} eventKey={index + (Number(grade) * 100)}>
                        <Accordion.Header>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <p style={{ padding: 0, margin: 0 }}>{grade}. Sınıf</p>
                            <p style={{ padding: 0, margin: "0 8px 0 0", }}>{hours}:{minutes}</p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body style={{ backgroundColor: "#F8F9FA" }}>
                          {
                            lessons && lessons.map((lesson, index) => {
                              if (lesson.grades.includes(grade)) {

                                let totalMinutes2 = 0

                                subjects.forEach((subject) => {
                                  if (subject.lessonId === lesson.uid && subject.grade === grade) {
                                    const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                                    if (studentData.studyTime) {
                                      if (studentData.studyTime[codingKey]) {
                                        totalMinutes2 += Number(studentData.studyTime[codingKey])
                                      }
                                    }
                                  }
                                })

                                let hours2 = ~~(totalMinutes2 / 60)
                                let minutes2 = totalMinutes2 - (hours2 * 60)

                                if (hours2 < 10) hours2 = `0${hours2}`
                                if (minutes2 < 10) minutes2 = `0${minutes2}`

                                return (
                                  <Accordion.Item key={index} eventKey={index + (Number(grade) * 200)}>
                                    <Accordion.Header>
                                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                        <p style={{ padding: 0, margin: 0 }}>{lesson.title}</p>
                                        <p style={{ padding: 0, margin: "0 8px 0 0", }}>{hours2}:{minutes2}</p>
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <Container style={{ display: "flex", flexDirection: "column", gap: "16px 0" }}>
                                        {
                                          subjects && subjects.map((subject, index) => {
                                            if (subject.grade === grade && subject.lessonId === lesson.uid) {

                                              let totalMinutes3 = 0

                                              const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                                              if (studentData.studyTime) {
                                                if (studentData.studyTime[codingKey]) {
                                                  totalMinutes3 = Number(studentData.studyTime[codingKey])
                                                }
                                              }

                                              let hours3 = ~~(totalMinutes3 / 60)
                                              let minutes3 = totalMinutes3 - (hours3 * 60)

                                              if (hours3 < 10) hours3 = `0${hours3}`
                                              if (minutes3 < 10) minutes3 = `0${minutes3}`

                                              return (
                                                <Row key={index}>
                                                  <div style={{ backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #dee2e6", padding: "12px 16px", borderRadius: "0.375rem" }}>
                                                    <p style={{ margin: 0, fontSize: "1rem", padding: 0 }}>{subject.title}</p>
                                                    <Form style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
                                                      <p style={{ padding: 0, margin: "0", }}>{hours3}:{minutes3}</p>
                                                    </Form>
                                                  </div>
                                                </Row>
                                              )
                                            } else {
                                              return null
                                            }
                                          })
                                        }
                                      </Container>

                                    </Accordion.Body>
                                  </Accordion.Item>
                                )
                              } else {
                                return null
                              }
                            })
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  })
                }
              </Accordion>
            </Col>
            <Col lg={3} md={2} sm={1} />
          </Row>
        </Container>
      </Container>
      }
    </>
  )
}
