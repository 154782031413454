import { createContext, useContext, useEffect, useState } from 'react'
import { auth, db, secondaryAuth, storage } from '../firebaseConfig'
import { doc, getDoc, onSnapshot, collection, query, where, getDocs, setDoc, updateDoc, arrayUnion, arrayRemove, deleteDoc, addDoc } from "firebase/firestore";
import { signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, updateEmail, updatePassword, deleteUser, fetchSignInMethodsForEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { deleteObject, ref } from 'firebase/storage';
import { uuidv4 } from '@firebase/util';

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const navigate = useNavigate()

  const [currentUser, setCurrentUser] = useState()
  const [userData, setUserData] = useState()
  const [realTimeUserData, setRealTimeUserData] = useState()
  const [loading, setLoading] = useState(true)

  async function login(username, password) {
    return signInWithEmailAndPassword(auth, `${username}@derecelgs.com`, password)
  }

  async function logOut() {
    return signOut(auth).then(() => {
      setCurrentUser()
      setUserData()
      setRealTimeUserData()
      navigate("/")
    })
  }

  async function checkIsExist(username) {
    let isExist = false

    await fetchSignInMethodsForEmail(auth, `${username}@derecelgs.com`)
      .then((signInMethods) => {
        if (signInMethods.length) {
          isExist = true
        }
      })

    // const q = query(collection(db, `admins`), where("email", "==", `${username}@derecelgs.com`));

    // const querySnapshot = await getDocs(q);

    // querySnapshot.forEach((doc) => {
    //   if (doc.data()) {
    //     isExist = true
    //   }
    // })

    // const q2 = query(collection(db, `organizations`), where("email", "==", `${username}@derecelgs.com`));

    // const querySnapshot2 = await getDocs(q2);

    // querySnapshot2.forEach((doc) => {
    //   if (doc.data()) {
    //     isExist = true
    //   }
    // })

    // const q3 = query(collection(db, `students`), where("email", "==", `${username}@derecelgs.com`));

    // const querySnapshot3 = await getDocs(q3);

    // querySnapshot3.forEach((doc) => {
    //   if (doc.data()) {
    //     isExist = true
    //   }
    // })

    // const q4 = query(collection(db, `teachers`), where("email", "==", `${username}@derecelgs.com`));

    // const querySnapshot4 = await getDocs(q4);

    // querySnapshot4.forEach((doc) => {
    //   if (doc.data()) {
    //     isExist = true
    //   }
    // })

    // const q5 = query(collection(db, `parents`), where("email", "==", `${username}@derecelgs.com`));

    // const querySnapshot5 = await getDocs(q5);

    // querySnapshot5.forEach((doc) => {
    //   if (doc.data()) {
    //     isExist = true
    //   }
    // })

    return isExist
  }

  const checkIsExistForRole = async (role, username) => {
    let isExist = false

    const q = query(collection(db, `${role}s`), where("email", "==", `${username}@derecelgs.com`));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      if (doc.data()) {
        isExist = true
      }
    })

    return isExist
  }

  const createNewOrganization = async (data) => {
    await createUserWithEmailAndPassword(secondaryAuth, data.email, data.password)
      .then(async (userCredential) => {
        const organizationData = {
          uid: userCredential.user.uid,
          name: data.name,
          tag: data.tag,
          email: data.email,
          adress: data.adress,
          phone: data.phone,
          username: data.username,
          password: data.password,
          role: data.role,
          createdAt: new Date().getTime()
        }
        await setDoc(doc(db, `${data.role}s`, userCredential.user.uid), organizationData);
        await secondaryAuth.signOut()
      })
  }

  const createNewExam = async (data) => {
    if (data.type === "grade") {
      const docRef = await addDoc(collection(db, "exams"), {
        createdAt: new Date().getTime(),
        studentId: data.studentId,
        name: data.name,
        type: data.type,
        grade: data.grade,
        questionNumber: String(data.questionNumber),
        turkishTrue: String(data.turkishTrue),
        turkishFalse: String(data.turkishFalse),
        turkishEmpty: String(data.turkishEmpty),
        socialTrue: String(data.socialTrue),
        socialFalse: String(data.socialFalse),
        socialEmpty: String(data.socialEmpty),
        dinTrue: String(data.dinTrue),
        dinFalse: String(data.dinFalse),
        dinEmpty: String(data.dinEmpty),
        ingTrue: String(data.ingTrue),
        ingFalse: String(data.ingFalse),
        ingEmpty: String(data.ingEmpty),
        mathTrue: String(data.mathTrue),
        mathFalse: String(data.mathFalse),
        mathEmpty: String(data.mathEmpty),
        scienceTrue: String(data.scienceTrue),
        scienceFalse: String(data.scienceFalse),
        scienceEmpty: String(data.scienceEmpty),
        totalNet: String(data.totalNet),
        turkishNet: String(data.turkishNet),
        socialNet: String(data.socialNet),
        dinNet: String(data.dinNet),
        ingNet: String(data.ingNet),
        mathNet: String(data.mathNet),
        scienceNet: String(data.scienceNet),
        organizationId: userData.organizationId
      });

      const washingtonRef = doc(db, "exams", docRef.id);

      await updateDoc(washingtonRef, {
        uid: docRef.id
      });

      await createNewActivity(`${data.grade}. sınıf denemesinden ${data.totalNet} net yaptı.`)
    }
    else if (data.type === "branch") {
      const docRef = await addDoc(collection(db, "exams"), {
        createdAt: new Date().getTime(),
        studentId: data.studentId,
        name: data.name,
        type: data.type,
        lesson: data.lesson,
        questionNumber: String(data.questionNumber),
        generalTrue: String(data.generalTrue),
        generalFalse: String(data.generalFalse),
        generalEmpty: String(data.generalEmpty),
        totalNet: String(data.totalNet),
        organizationId: userData.organizationId
      });

      const washingtonRef = doc(db, "exams", docRef.id);

      await updateDoc(washingtonRef, {
        uid: docRef.id
      });

      await createNewActivity(`${data.lesson} branş denemesinden ${data.totalNet} net yaptı.`)
    }
    else if (data.type === "lgs") {
      const docRef = await addDoc(collection(db, "exams"), {
        createdAt: new Date().getTime(),
        studentId: data.studentId,
        name: data.name,
        type: data.type,
        questionNumber: String(data.questionNumber),
        turkishTrue: String(data.turkishTrue),
        turkishFalse: String(data.turkishFalse),
        turkishEmpty: String(data.turkishEmpty),
        socialTrue: String(data.socialTrue),
        socialFalse: String(data.socialFalse),
        socialEmpty: String(data.socialEmpty),
        dinTrue: String(data.dinTrue),
        dinFalse: String(data.dinFalse),
        dinEmpty: String(data.dinEmpty),
        ingTrue: String(data.ingTrue),
        ingFalse: String(data.ingFalse),
        ingEmpty: String(data.ingEmpty),
        mathTrue: String(data.mathTrue),
        mathFalse: String(data.mathFalse),
        mathEmpty: String(data.mathEmpty),
        scienceTrue: String(data.scienceTrue),
        scienceFalse: String(data.scienceFalse),
        scienceEmpty: String(data.scienceEmpty),
        totalNet: String(data.totalNet),
        turkishNet: String(data.turkishNet),
        socialNet: String(data.socialNet),
        dinNet: String(data.dinNet),
        ingNet: String(data.ingNet),
        mathNet: String(data.mathNet),
        scienceNet: String(data.scienceNet),
        organizationId: userData.organizationId
      });

      const washingtonRef = doc(db, "exams", docRef.id);

      await updateDoc(washingtonRef, {
        uid: docRef.id
      });

      const logRef = doc(db, "students", realTimeUserData.uid);
      await updateDoc(logRef, {
        lgsExamsLogs: arrayUnion({
          id: uuidv4(),
          uid: docRef.id,
          value: data.totalNet,
          date: new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
          timestamp: new Date().getTime()
        })
      });

      await createNewActivity(`LGS denemesinden ${data.totalNet} net yaptı.`)
    }
    // else if (data.type === "ayt") {
    //   if (data.domain === "SAY") {
    //     const docRef = await addDoc(collection(db, "exams"), {
    //       createdAt: new Date().getTime(),
    //       studentId: data.studentId,
    //       name: data.name,
    //       type: data.type,
    //       domain: data.domain,
    //       questionNumber: String(data.questionNumber),
    //       mathTrue: String(data.mathTrue),
    //       mathFalse: String(data.mathFalse),
    //       mathEmpty: String(data.mathEmpty),
    //       physicsTrue: String(data.physicsTrue),
    //       physicsFalse: String(data.physicsFalse),
    //       physicsEmpty: String(data.physicsEmpty),
    //       chemistryTrue: String(data.chemistryTrue),
    //       chemistryFalse: String(data.chemistryFalse),
    //       chemistryEmpty: String(data.chemistryEmpty),
    //       biologyTrue: String(data.biologyTrue),
    //       biologyFalse: String(data.biologyFalse),
    //       biologyEmpty: String(data.biologyEmpty),
    //       mathNet: String(data.mathNet),
    //       physicsNet: String(data.physicsNet),
    //       chemistryNet: String(data.chemistryNet),
    //       biologyNet: String(data.biologyNet),
    //       totalNet: String(data.totalNet),
    //       organizationId: userData.organizationId
    //     });

    //     const washingtonRef = doc(db, "exams", docRef.id);

    //     await updateDoc(washingtonRef, {
    //       uid: docRef.id
    //     });


    //     const logRef = doc(db, "students", realTimeUserData.uid);
    //     await updateDoc(logRef, {
    //       aytExamsLogs: arrayUnion({
    //         id: uuidv4(),
    //         uid: docRef.id,
    //         value: data.totalNet,
    //         date: new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
    //         timestamp: new Date().getTime()
    //       })
    //     });

    //     await createNewActivity(`AYT (SAY) denemesinden ${data.totalNet} net yaptı.`)
    //   }

    //   else if (data.domain === "EA") {
    //     const docRef = await addDoc(collection(db, "exams"), {
    //       createdAt: new Date().getTime(),
    //       studentId: data.studentId,
    //       name: data.name,
    //       type: data.type,
    //       domain: data.domain,
    //       questionNumber: String(data.questionNumber),
    //       mathTrue: String(data.mathTrue),
    //       mathFalse: String(data.mathFalse),
    //       mathEmpty: String(data.mathEmpty),
    //       edebiyatTrue: String(data.edebiyatTrue),
    //       edebiyatFalse: String(data.edebiyatFalse),
    //       edebiyatEmpty: String(data.edebiyatEmpty),
    //       tarihTrue: String(data.tarihTrue),
    //       tarihFalse: String(data.tarihFalse),
    //       tarihEmpty: String(data.tarihEmpty),
    //       cografyaTrue: String(data.cografyaTrue),
    //       cografyaFalse: String(data.cografyaFalse),
    //       cografyaEmpty: String(data.cografyaEmpty),
    //       mathNet: String(data.mathNet),
    //       edebiyatNet: String(data.edebiyatNet),
    //       tarihNet: String(data.tarihNet),
    //       cografyaNet: String(data.cografyaNet),
    //       totalNet: String(data.totalNet),
    //       organizationId: userData.organizationId
    //     });

    //     const washingtonRef = doc(db, "exams", docRef.id);

    //     await updateDoc(washingtonRef, {
    //       uid: docRef.id
    //     });

    //     const logRef = doc(db, "students", realTimeUserData.uid);
    //     await updateDoc(logRef, {
    //       aytExamsLogs: arrayUnion({
    //         id: uuidv4(),
    //         uid: docRef.id,
    //         value: data.totalNet,
    //         date: new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
    //         timestamp: new Date().getTime()
    //       })
    //     });

    //     await createNewActivity(`AYT (EA) denemesinden ${data.totalNet} net yaptı.`)

    //   }
    //   else if (data.domain === "SÖZ") {
    //     const docRef = await addDoc(collection(db, "exams"), {
    //       createdAt: new Date().getTime(),
    //       studentId: data.studentId,
    //       name: data.name,
    //       type: data.type,
    //       domain: data.domain,
    //       questionNumber: String(data.questionNumber),
    //       edebiyatTrue: String(data.edebiyatTrue),
    //       edebiyatFalse: String(data.edebiyatFalse),
    //       edebiyatEmpty: String(data.edebiyatEmpty),
    //       tarih1True: String(data.tarih1True),
    //       tarih1False: String(data.tarih1False),
    //       tarih1Empty: String(data.tarih1Empty),
    //       cografya1True: String(data.cografya1True),
    //       cografya1False: String(data.cografya1False),
    //       cografya1Empty: String(data.cografya1Empty),
    //       tarih2True: String(data.tarih2True),
    //       tarih2False: String(data.tarih2False),
    //       tarih2Empty: String(data.tarih2Empty),
    //       cografya2True: String(data.cografya2True),
    //       cografya2False: String(data.cografya2False),
    //       cografya2Empty: String(data.cografya2Empty),
    //       felsefeTrue: String(data.felsefeTrue),
    //       felsefeFalse: String(data.felsefeFalse),
    //       felsefeEmpty: String(data.felsefeEmpty),
    //       dinTrue: String(data.dinTrue),
    //       dinFalse: String(data.dinFalse),
    //       dinEmpty: String(data.dinEmpty),
    //       edebiyatNet: String(data.edebiyatNet),
    //       tarih1Net: String(data.tarih1Net),
    //       tarih2Net: String(data.tarih2Net),
    //       cografya1Net: String(data.cografya1Net),
    //       cografya2Net: String(data.cografya2Net),
    //       felsefeNet: String(data.felsefeNet),
    //       dinNet: String(data.dinNet),
    //       totalNet: String(data.totalNet),
    //       organizationId: userData.organizationId
    //     });

    //     const washingtonRef = doc(db, "exams", docRef.id);

    //     await updateDoc(washingtonRef, {
    //       uid: docRef.id
    //     });

    //     const logRef = doc(db, "students", realTimeUserData.uid);
    //     await updateDoc(logRef, {
    //       aytExamsLogs: arrayUnion({
    //         id: uuidv4(),
    //         uid: docRef.id,
    //         value: data.totalNet,
    //         date: new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
    //         timestamp: new Date().getTime()
    //       })
    //     });

    //     await createNewActivity(`AYT (SÖZ) denemesinden ${data.totalNet} net yaptı.`)

    //   }
    // }
    // else if (data.type === "ydt") {
    //   const docRef = await addDoc(collection(db, "exams"), {
    //     createdAt: new Date().getTime(),
    //     studentId: data.studentId,
    //     name: data.name,
    //     type: data.type,
    //     lang: data.lang,
    //     questionNumber: String(data.questionNumber),
    //     langTrue: String(data.langTrue),
    //     langFalse: String(data.langFalse),
    //     langEmpty: String(data.langEmpty),
    //     totalNet: String(data.totalNet),
    //     organizationId: userData.organizationId
    //   });

    //   const washingtonRef = doc(db, "exams", docRef.id);

    //   await updateDoc(washingtonRef, {
    //     uid: docRef.id
    //   });

    //   const logRef = doc(db, "students", realTimeUserData.uid);
    //   await updateDoc(logRef, {
    //     ydtExamsLogs: arrayUnion({
    //       id: uuidv4(),
    //       uid: docRef.id,
    //       value: data.totalNet,
    //       date: new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
    //       timestamp: new Date().getTime()
    //     })
    //   });

    //   await createNewActivity(`YDT denemesinden ${data.totalNet} net yaptı.`)

    // }
  }

  const createNewStudent = async (data) => {
    let studentUid;
    const parentPassword = generatePassword()

    await createUserWithEmailAndPassword(secondaryAuth, data.email, data.password)
      .then(async (userCredential) => {
        studentUid = userCredential.user.uid

        const studentData = {
          uid: userCredential.user.uid,
          name: data.name,
          surname: data.surname,
          email: data.email,
          studentNo: data.studentNo,
          classCode: data.classCode,
          grade: data.grade,
          username: data.username,
          password: data.password,
          role: data.role,
          organizationId: data.organizationId,
          createdAt: new Date().getTime()
        }
        await setDoc(doc(db, `${data.role}s`, userCredential.user.uid), studentData);
        await secondaryAuth.signOut()

        // const orgRef = doc(db, "organizations", currentUser.uid);

        // await updateDoc(orgRef, {
        //   students: arrayUnion(userCredential.user.uid)
        // });
      })

    await createUserWithEmailAndPassword(secondaryAuth, `${data.username}veli@derecelgs.com`, parentPassword)
      .then(async (userCredential) => {
        const parentData = {
          uid: userCredential.user.uid,
          name: `${data.name} ${data.surname} Velisi`,
          email: `${data.username}veli@derecelgs.com`,
          username: `${data.username}veli`,
          password: parentPassword,
          role: "parent",
          childId: studentUid,
          childName: `${data.name} ${data.surname}`,
          childGrade: data.grade,
          childStudentNo: data.studentNo,
          childClassCode: data.classCode,
          organizationId: data.organizationId,
          createdAt: new Date().getTime()
        }
        await setDoc(doc(db, "parents", userCredential.user.uid), parentData);
        await secondaryAuth.signOut()

        // const orgRef = doc(db, "organizations", currentUser.uid);

        // await updateDoc(orgRef, {
        //   parents: arrayUnion(userCredential.user.uid)
        // });
      })
  }

  const createNewTeacher = async (data) => {
    await createUserWithEmailAndPassword(secondaryAuth, data.email, data.password)
      .then(async (userCredential) => {
        const teacherData = {
          uid: userCredential.user.uid,
          name: data.name,
          surname: data.surname,
          email: data.email,
          branches: data.branches,
          students: data.students,
          username: data.username,
          password: data.password,
          role: data.role,
          organizationId: data.organizationId,
          createdAt: new Date().getTime()
        }
        await setDoc(doc(db, `${data.role}s`, userCredential.user.uid), teacherData);
        await secondaryAuth.signOut()

        // const orgRef = doc(db, "organizations", currentUser.uid);

        // await updateDoc(orgRef, {
        //   teachers: arrayUnion(userCredential.user.uid)
        // });

        await data.students.forEach(async (studentId) => {
          const studentRef = doc(db, "students", studentId);

          await updateDoc(studentRef, {
            teachers: arrayUnion(userCredential.user.uid)
          });
        })
      })
  }

  const updateOrganizationData = async (uid, oldData, data) => {
    // hem username email hem de password değişirse
    if (oldData.username !== data.username && oldData.password !== data.password) {
      // email güncellenecek auth
      await signInWithEmailAndPassword(secondaryAuth, oldData.email, oldData.password)
      await updateEmail(secondaryAuth.currentUser, data.email)
      // password güncelle auth
      await updatePassword(secondaryAuth.currentUser, data.password)
      // dbyi güncelle data ile
      const dataRef = doc(db, "organizations", uid);
      await updateDoc(dataRef, {
        name: data.name,
        tag: data.tag,
        email: data.email,
        adress: data.adress,
        phone: data.phone,
        username: data.username,
        password: data.password,
      });
      // kullanıcıyı at
      await secondaryAuth.signOut()
    }

    // sadece username email değişirse
    else if (oldData.username !== data.username && oldData.password === data.password) {
      // email güncellenecek auth
      await signInWithEmailAndPassword(secondaryAuth, oldData.email, oldData.password)
      await updateEmail(secondaryAuth.currentUser, data.email)
      // dbyi güncelle data ile
      const dataRef = doc(db, "organizations", uid);
      await updateDoc(dataRef, {
        name: data.name,
        tag: data.tag,
        email: data.email,
        adress: data.adress,
        phone: data.phone,
        username: data.username,
      });
      // kullanıcıyı at
      await secondaryAuth.signOut()
    }

    // sadece password değişirse
    else if (oldData.username === data.username && oldData.password !== data.password) {
      await signInWithEmailAndPassword(secondaryAuth, oldData.email, oldData.password)
      // password güncelle auth
      await updatePassword(secondaryAuth.currentUser, data.password)
      // dbyi güncelle data ile
      const dataRef = doc(db, "organizations", uid);
      await updateDoc(dataRef, {
        name: data.name,
        tag: data.tag,
        adress: data.adress,
        phone: data.phone,
        password: data.password,
      });
      // kullanıcıyı at
      await secondaryAuth.signOut()
    }

    // password usernmae email değişmezse
    else {
      const dataRef = doc(db, "organizations", uid);
      await updateDoc(dataRef, {
        name: data.name,
        tag: data.tag,
        adress: data.adress,
        phone: data.phone,
      });
    }
  }

  const updateStudentData = async (uid, data) => {
    const dataRef = doc(db, "students", uid);
    await updateDoc(dataRef, {
      grade: data.grade,
      classCode: data.classCode,
      studentNo: data.studentNo
    });
  }

  const updateTeacherData = async (uid, data, oldStudents) => {
    const dataRef = doc(db, "teachers", uid);
    await updateDoc(dataRef, {
      branches: data.branches,
      students: data.students
    });

    const diff = [];

    await oldStudents.forEach((studentId) => {
      if (!data.students.includes(studentId)) {
        diff.push(studentId)
      }
    })

    await data.students.forEach(async (studentId) => {
      const studentRef = doc(db, "students", studentId);

      await updateDoc(studentRef, {
        teachers: arrayUnion(uid)
      });
    })

    if (diff) {
      if (diff.length > 0) {
        diff.forEach(async (studentId) => {
          const studentRef = doc(db, "students", studentId);

          await updateDoc(studentRef, {
            teachers: arrayRemove(uid)
          });
        })
      }
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    if (currentUser) {
      getUserData(currentUser.uid)
    }
  }, [currentUser])

  async function getUserData(uid) {
    let docRef = doc(db, "organizations", uid);
    let docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUserData(docSnap.data())
    } else {
      docRef = doc(db, "teachers", uid);
      docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserData(docSnap.data())
      } else {
        docRef = doc(db, "parents", uid);
        docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserData(docSnap.data())
        } else {
          docRef = doc(db, "admins", uid);
          docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUserData(docSnap.data())
          } else {
            docRef = doc(db, "students", uid);
            docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              setUserData(docSnap.data())
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (userData && currentUser) {
      const unsub = onSnapshot(doc(db, `${userData.role}s`, currentUser.uid), (doc) => {
        setRealTimeUserData(doc.data())
      });
      return unsub
    }
  }, [userData, currentUser])

  // useEffect(() => {
  //   if (currentUser && userData && realTimeUserData) {
  //     console.log({
  //       "current user": currentUser,
  //       "user data": userData,
  //       "realtime user data": realTimeUserData,
  //     });
  //   }
  // }, [currentUser, userData, realTimeUserData])

  // useEffect(() => {
  //   if (currentUser) console.log(currentUser);
  // }, [currentUser])

  function generatePassword() {
    var length = 8,
      charset = "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const deleteStudent = async (student) => {
    await signInWithEmailAndPassword(secondaryAuth, student.email, student.password)

    await deleteUser(secondaryAuth.currentUser).then(async () => {
      const studentRef = doc(db, "students", student.uid);
      await deleteDoc(studentRef);

      if (student.teachers) {
        if (student.teachers.length > 0) {
          await student.teachers.forEach(async (teacherId) => {
            const teacherRef = doc(db, "teachers", teacherId);

            await updateDoc(teacherRef, {
              students: arrayRemove(student.uid),
            });
          })
        }
      }
    })

    await secondaryAuth.signOut()

    const q = query(collection(db, "parents"), where("childId", "==", student.uid));

    const querySnapshot = await getDocs(q);

    let parentData;

    querySnapshot.forEach((doc) => {
      parentData = doc.data()
    });

    if (parentData) {
      await signInWithEmailAndPassword(secondaryAuth, parentData.email, parentData.password)

      await deleteUser(secondaryAuth.currentUser).then(async () => {
        const parentRef = doc(db, "parents", parentData.uid);
        await deleteDoc(parentRef);
      })

      await secondaryAuth.signOut()
    }
  }

  const deleteTeacher = async (teacher) => {
    await signInWithEmailAndPassword(secondaryAuth, teacher.email, teacher.password)

    await deleteUser(secondaryAuth.currentUser).then(async () => {
      const teacherRef = doc(db, "teachers", teacher.uid);
      await deleteDoc(teacherRef);

      if (teacher.students) {
        if (teacher.students.length > 0) {
          await teacher.students.forEach(async (studentId) => {
            const studentRef = doc(db, "students", studentId);

            await updateDoc(studentRef, {
              teachers: arrayRemove(teacher.uid),
            });
          })
        }
      }
    })

    await secondaryAuth.signOut()
  }

  const deleteOrganization = async (organization) => {
    await signInWithEmailAndPassword(secondaryAuth, organization.email, organization.password)

    await deleteUser(secondaryAuth.currentUser).then(async () => {
      const organizationRef = doc(db, "organizations", organization.uid);
      await deleteDoc(organizationRef);
    })

    await secondaryAuth.signOut()

    // velileri sil
    const q = query(collection(db, "parents"), where("organizationId", "==", organization.uid));

    const querySnapshot = await getDocs(q);

    let parentList = []

    querySnapshot.forEach((doc) => {
      parentList.push(doc.data())
    });

    if (parentList) {
      if (parentList.length > 0) {
        parentList.forEach(async (parent) => {
          await signInWithEmailAndPassword(secondaryAuth, parent.email, parent.password)

          await deleteUser(secondaryAuth.currentUser).then(async () => {
            const parentRef = doc(db, "parents", parent.uid);
            await deleteDoc(parentRef);
          })

          await secondaryAuth.signOut()
        })
      }
    }

    // öğrencileri sil
    const q2 = query(collection(db, "students"), where("organizationId", "==", organization.uid));

    const querySnapshot2 = await getDocs(q2);

    let studentList = []

    querySnapshot2.forEach((doc) => {
      studentList.push(doc.data())
    });

    if (studentList) {
      if (studentList.length > 0) {
        studentList.forEach(async (student) => {
          await signInWithEmailAndPassword(secondaryAuth, student.email, student.password)

          await deleteUser(secondaryAuth.currentUser).then(async () => {
            const studentRef = doc(db, "students", student.uid);
            await deleteDoc(studentRef);
          })

          await secondaryAuth.signOut()
        })
      }
    }

    // öğretmenleri sil
    const q3 = query(collection(db, "teachers"), where("organizationId", "==", organization.uid));

    const querySnapshot3 = await getDocs(q3);

    let teacherList = []

    querySnapshot3.forEach((doc) => {
      teacherList.push(doc.data())
    });

    if (teacherList) {
      if (teacherList.length > 0) {
        teacherList.forEach(async (teacher) => {
          await signInWithEmailAndPassword(secondaryAuth, teacher.email, teacher.password)

          await deleteUser(secondaryAuth.currentUser).then(async () => {
            const teacherRef = doc(db, "teachers", teacher.uid);
            await deleteDoc(teacherRef);
          })

          await secondaryAuth.signOut()
        })
      }
    }

    // last aktivitiesi sil
    const lastActivities = [];
    const querySnapshot4 = await getDocs(query(collection(db, "lastActivities"), where("organizationId", "==", organization.uid)));

    querySnapshot4.forEach((doc) => {
      lastActivities.push(doc.data())
    });

    if (lastActivities && lastActivities.length > 0) {
      lastActivities.forEach(async (activity) => {
        await deleteDoc(doc(db, "lastActivities", activity.uid));
      })
    }

    // denemeleri sil
    const exams = [];
    const querySnapshot5 = await getDocs(query(collection(db, "exams"), where("organizationId", "==", organization.uid)));

    querySnapshot5.forEach((doc) => {
      exams.push(doc.data())
    });

    if (exams && exams.length > 0) {
      exams.forEach(async (exam) => {
        await deleteDoc(doc(db, "exams", exam.uid));
      })
    }

    // sorular ve storagedaki imagelar silinecek
    const questions = [];
    const querySnapshot6 = await getDocs(query(collection(db, "questions"), where("organizationId", "==", organization.uid)));

    querySnapshot6.forEach((doc) => {
      questions.push(doc.data())
    });

    if (questions && questions.length > 0) {
      questions.forEach(async (question) => {

        const questionRef = ref(storage, `questions/${question.imageFileName}`);

        await deleteObject(questionRef)

        if (question.solutionImage && question.solutionImageFileName) {
          const solutionRef = ref(storage, `solutions/${question.solutionImageFileName}`);

          await deleteObject(solutionRef)
        }

        await deleteDoc(doc(db, "questions", question.uid));
      })
    }
  }

  const createNewActivity = async (message) => {
    const lastActivities = [];
    const querySnapshot = await getDocs(query(collection(db, "lastActivities"), where("organizationId", "==", userData.organizationId)));

    querySnapshot.forEach((doc) => {
      lastActivities.push(doc.data())
    });

    lastActivities.sort((x, y) => {
      return x.createdAt - y.createdAt
    })

    if (lastActivities.length > 24) {
      const willClear = lastActivities.slice(0, lastActivities.length - 24)
      willClear.forEach( async (activity) => {
        await deleteDoc(doc(db, "lastActivities", activity.uid));
      })
    }

    const docRef = await addDoc(collection(db, "lastActivities"), {
      createdAt: new Date().getTime(),
      studentId: userData.uid,
      name: userData.name,
      surname: userData.surname,
      message: message,
      organizationId: userData.organizationId
    });

    const washingtonRef = doc(db, "lastActivities", docRef.id);

    await updateDoc(washingtonRef, {
      uid: docRef.id
    });
  }


  const value = {
    currentUser,
    userData,
    realTimeUserData,
    loading,
    login,
    logOut,
    checkIsExist,
    checkIsExistForRole,
    createNewOrganization,
    updateOrganizationData,
    createNewStudent,
    updateStudentData,
    generatePassword,
    createNewTeacher,
    updateTeacherData,
    deleteStudent,
    deleteTeacher,
    deleteOrganization,
    createNewExam,
    createNewActivity,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )

}