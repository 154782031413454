import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Navbar } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import lessons from "../../datas/lessonsForLgs.json"
import subjects from "../../datas/subjectsForLgs.json"
import lgsLessons from "../../datas/lgsLessons.json"
import lgsSubjects from "../../datas/lgsSubjects.json"
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, Title, CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, Title, CategoryScale,
  LinearScale,
  PointElement,
  LineElement);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: false,
      text: '5. Sınıf Tamamlama Yüzdesi Dilim Grafiği',
    },
  },
};

export default function StudentPanelOverview() {


  useEffect(() => {

  }, [])

  const { userData } = useAuth()

  const [fiveGradeSubjectTrackingData, setFiveGradeSubjectTrackingData] = useState({
    labels: [],
    datasets: [
      {
        label: '%',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  })
  const [sixGradeSubjectTrackingData, setSixGradeSubjectTrackingData] = useState({
    labels: [],
    datasets: [
      {
        label: '%',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  })
  const [sevenGradeSubjectTrackingData, setSevenGradeSubjectTrackingData] = useState({
    labels: [],
    datasets: [
      {
        label: '%',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  })
  const [eightGradeSubjectTrackingData, setEightGradeSubjectTrackingData] = useState({
    labels: [],
    datasets: [
      {
        label: '%',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  })
  const [lgsSubjectTrackingData, setLGSSubjectTrackingData] = useState({
    labels: [],
    datasets: [
      {
        label: '%',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  })


  const [fiveGradeShow, setFiveGradeShow] = useState(false)
  const [sixGradeShow, setSixGradeShow] = useState(false)
  const [sevenGradeShow, setSevenGradeShow] = useState(false)
  const [eightGradeShow, setEightGradeShow] = useState(false)
  const [lgsShow, setLGSShow] = useState(false)

  useEffect(() => {
    if (fiveGradeSubjectTrackingData) {
      const lessonTitles = []
      lessons.forEach((lesson) => {
        if (lesson.grades.includes("5")) {
          lessonTitles.push(lesson.title)
        }
      })

      const copyOfData = fiveGradeSubjectTrackingData
      copyOfData.labels = lessonTitles

      let index = 0

      lessons && lessons.forEach((lesson) => {
        if (lesson.grades.includes("5")) {

          let numberOfSubjects = 0

          subjects.forEach((subject) => {
            if (subject.lessonId === lesson.uid && subject.grade === "5") {
              numberOfSubjects += 1
            }
          })

          let checkedSubjects = 0

          subjects.forEach((subject) => {
            if (subject.lessonId === lesson.uid && subject.grade === "5") {
              const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

              if (userData.subjectTracking) {
                if (userData.subjectTracking[codingKey]) {
                  checkedSubjects += 1
                }
              }
            }
          })

          const persentageOfSubjectCompletion = Math.round((checkedSubjects / numberOfSubjects) * 100)

          copyOfData.datasets[0].data[index] = persentageOfSubjectCompletion

          index += 1
        }
      }
      )

      let isZero = true

      copyOfData.datasets[0].data.forEach((i) => {
        if (i !== 0) {
          isZero = false
        }
      })

      setFiveGradeShow(!isZero)

      setFiveGradeSubjectTrackingData(copyOfData)
    }
  }, [fiveGradeSubjectTrackingData, userData])

  useEffect(() => {
    if (sixGradeSubjectTrackingData) {
      const lessonTitles = []
      lessons.forEach((lesson) => {
        if (lesson.grades.includes("6")) {
          lessonTitles.push(lesson.title)
        }
      })

      const copyOfData = sixGradeSubjectTrackingData
      copyOfData.labels = lessonTitles

      let index = 0

      lessons && lessons.forEach((lesson) => {
        if (lesson.grades.includes("6")) {

          let numberOfSubjects = 0

          subjects.forEach((subject) => {
            if (subject.lessonId === lesson.uid && subject.grade === "6") {
              numberOfSubjects += 1
            }
          })

          let checkedSubjects = 0

          subjects.forEach((subject) => {
            if (subject.lessonId === lesson.uid && subject.grade === "6") {
              const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

              if (userData.subjectTracking) {
                if (userData.subjectTracking[codingKey]) {
                  checkedSubjects += 1
                }
              }
            }
          })

          const persentageOfSubjectCompletion = Math.round((checkedSubjects / numberOfSubjects) * 100)

          copyOfData.datasets[0].data[index] = persentageOfSubjectCompletion

          index += 1
        }
      }
      )

      let isZero = true

      copyOfData.datasets[0].data.forEach((i) => {
        if (i !== 0) {
          isZero = false
        }
      })

      setSixGradeShow(!isZero)

      setSixGradeSubjectTrackingData(copyOfData)
    }
  }, [sixGradeSubjectTrackingData, userData])

  useEffect(() => {
    if (sevenGradeSubjectTrackingData) {
      const lessonTitles = []
      lessons.forEach((lesson) => {
        if (lesson.grades.includes("7")) {
          lessonTitles.push(lesson.title)
        }
      })

      const copyOfData = sevenGradeSubjectTrackingData
      copyOfData.labels = lessonTitles

      let index = 0

      lessons && lessons.forEach((lesson) => {
        if (lesson.grades.includes("7")) {

          let numberOfSubjects = 0

          subjects.forEach((subject) => {
            if (subject.lessonId === lesson.uid && subject.grade === "7") {
              numberOfSubjects += 1
            }
          })

          let checkedSubjects = 0

          subjects.forEach((subject) => {
            if (subject.lessonId === lesson.uid && subject.grade === "7") {
              const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

              if (userData.subjectTracking) {
                if (userData.subjectTracking[codingKey]) {
                  checkedSubjects += 1
                }
              }
            }
          })

          const persentageOfSubjectCompletion = Math.round((checkedSubjects / numberOfSubjects) * 100)

          copyOfData.datasets[0].data[index] = persentageOfSubjectCompletion

          index += 1
        }
      }
      )

      let isZero = true

      copyOfData.datasets[0].data.forEach((i) => {
        if (i !== 0) {
          isZero = false
        }
      })

      setSevenGradeShow(!isZero)

      setSevenGradeSubjectTrackingData(copyOfData)
    }
  }, [sevenGradeSubjectTrackingData, userData])

  useEffect(() => {
    if (eightGradeSubjectTrackingData) {
      const lessonTitles = []
      lessons.forEach((lesson) => {
        if (lesson.grades.includes("8")) {
          lessonTitles.push(lesson.title)
        }
      })

      const copyOfData = eightGradeSubjectTrackingData
      copyOfData.labels = lessonTitles

      let index = 0

      lessons && lessons.forEach((lesson) => {
        if (lesson.grades.includes("8")) {

          let numberOfSubjects = 0

          subjects.forEach((subject) => {
            if (subject.lessonId === lesson.uid && subject.grade === "8") {
              numberOfSubjects += 1
            }
          })

          let checkedSubjects = 0

          subjects.forEach((subject) => {
            if (subject.lessonId === lesson.uid && subject.grade === "8") {
              const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

              if (userData.subjectTracking) {
                if (userData.subjectTracking[codingKey]) {
                  checkedSubjects += 1
                }
              }
            }
          })

          const persentageOfSubjectCompletion = Math.round((checkedSubjects / numberOfSubjects) * 100)

          copyOfData.datasets[0].data[index] = persentageOfSubjectCompletion

          index += 1
        }
      }
      )

      let isZero = true

      copyOfData.datasets[0].data.forEach((i) => {
        if (i !== 0) {
          isZero = false
        }
      })

      setEightGradeShow(!isZero)

      setEightGradeSubjectTrackingData(copyOfData)
    }
  }, [eightGradeSubjectTrackingData, userData])

  useEffect(() => {
    if (lgsSubjectTrackingData) {
      const lessonTitles = []
      lgsLessons.forEach((lesson) => {
        lessonTitles.push(lesson.title)
      })

      const copyOfData = lgsSubjectTrackingData
      copyOfData.labels = lessonTitles

      let index = 0

      lgsLessons && lgsLessons.forEach((lesson) => {


        let numberOfSubjects = 0

        lgsSubjects.forEach((subject) => {
          if (subject.lessonId === lesson.uid) {
            numberOfSubjects += 1
          }
        })

        let checkedSubjects = 0

        lgsSubjects.forEach((subject) => {
          if (subject.lessonId === lesson.uid) {
            const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

            if (userData.subjectTracking) {
              if (userData.subjectTracking[codingKey]) {
                checkedSubjects += 1
              }
            }
          }
        })

        const persentageOfSubjectCompletion = Math.round((checkedSubjects / numberOfSubjects) * 100)

        copyOfData.datasets[0].data[index] = persentageOfSubjectCompletion

        index += 1

      }
      )

      let isZero = true

      copyOfData.datasets[0].data.forEach((i) => {
        if (i !== 0) {
          isZero = false
        }
      })

      setLGSShow(!isZero)

      setLGSSubjectTrackingData(copyOfData)
    }
  }, [lgsSubjectTrackingData, userData])



  const [studyTimeLabels7, setStudyTimeLabels7] = useState([])

  const [studyTimeData7, setStudyTimeData7] = useState({
    labels: studyTimeLabels7,
    datasets: [
      {
        label: 'Ders Çalışma Süresi',
        data: studyTimeLabels7.map(() => 0),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  })

  const [studyTimeLabels30, setStudyTimeLabels30] = useState([])

  const [studyTimeData30, setStudyTimeData30] = useState({
    labels: studyTimeLabels30,
    datasets: [
      {
        label: 'Ders Çalışma Süresi',
        data: studyTimeLabels30.map(() => 0),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  })

  useEffect(() => {
    const today = new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })

    const logs = userData.studyTimeLogs

    if (logs) {
      logs.sort((log1, log2) => log2.timestamp - log1.timestamp)

      logs.forEach((log, index) => {
        if (log.date.split(".")[0].length === 1) {
          log.date = `0${log.date.split(".")[0]}.${log.date.split(".")[1]}.${log.date.split(".")[2]}`
        }
      })

      const dates = []
      const timestamps = []
      const values = []

      logs.forEach((log) => {
        if (dates.includes(log.date)) {
          try {
            if (dates.length > 0)  {
              const findedIndex = dates.indexOf(log.date)
              const oldValue = values[findedIndex]
              const newValue = Number(oldValue) + Number(log.value)
              values[findedIndex] = newValue
            }
           
          } catch (error) {
            console.log(error);
          }

        } else {
          dates.push(log.date)
          timestamps.push(log.timestamp)
          values.push(Number(log.value))
        }
      })

      // dates.forEach((date, index) => console.log("DATE:", index + 1, date))
      // timestamps.forEach((timestamp, index) => console.log("TIMESTAMP:", index + 1, new Date(timestamp).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })))

      let last7Days = []
      let last7Timestamps = []
      let last7Values = []

      let last30Days = []
      let last30Timestamps = []
      let last30Values = []

      if (dates[0] === today) {
        if (dates.length >= 8) {
          last7Days = dates.slice(0, 7)
          last7Timestamps = timestamps.slice(0, 7)
          last7Values = values.slice(0, 7)
        } else {
          last7Days = dates.slice()
          last7Timestamps = timestamps.slice()
          last7Values = values.slice()
        }
      } else {
        if (dates.length >= 8) {
          last7Days = dates.slice(0, 7)
          last7Timestamps = timestamps.slice(0, 7)
          last7Values = values.slice(0, 7)
        } else {
          last7Days = dates.slice()
          last7Timestamps = timestamps.slice()
          last7Values = values.slice()
        }
      }

      if (dates[0] === today) {
        if (dates.length >= 31) {
          last30Days = dates.slice(0, 30)
          last30Timestamps = timestamps.slice(0, 30)
          last30Values = values.slice(0, 30)
        } else {
          last30Days = dates.slice()
          last30Timestamps = timestamps.slice()
          last30Values = values.slice()
        }
      } else {
        if (dates.length >= 31) {
          last30Days = dates.slice(0, 30)
          last30Timestamps = timestamps.slice(0, 30)
          last30Values = values.slice(0, 30)
        } else {
          last30Days = dates.slice()
          last30Timestamps = timestamps.slice()
          last30Values = values.slice()
        }
      }

      const indexDate = new Date().getTime()

      last7Timestamps.forEach((timestamp, index) => {
        if (Math.round((indexDate - timestamp) / (1000 * 60 * 60 * 24)) >= 7) {
          last7Days.splice(index, 1)
          last7Timestamps.splice(index, 1)
          last7Values.splice(index, 1)
        }
      })

      const neededDates = [
        new Date(indexDate - 0).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 1)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 2)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 3)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 4)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 5)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 6)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })
      ]

      neededDates.forEach((log, index) => {
        if (log.split(".")[0].length === 1) {
          neededDates[index] = `0${log.split(".")[0]}.${log.split(".")[1]}.${log.split(".")[2]}`
        }
      })

      const neededValues = []

      neededDates.forEach((day, index) => {
        if (last7Days.includes(day)) {
          try {
            if (last7Days.length > 0) {
              const thatIndex = last7Days.indexOf(day)
              neededValues[index] = last7Values[thatIndex]
            }
            
          } catch (error) {
            console.log(error);
          }

        } else {
          neededValues[index] = 0
        }
      })

      // console.log("DATES 7:", neededDates);
      // console.log("VALUES 7:", neededValues);

      neededDates.reverse()
      neededValues.reverse()

      const lastDates = []

      neededDates.forEach((date) => {
        lastDates.push(`${date.split(".")[0]}.${date.split(".")[1]}`)
      })

      setStudyTimeLabels7(lastDates)
      setStudyTimeData7({
        labels: lastDates,
        datasets: [
          {
            label: 'Ders Çalışma Süresi',
            data: lastDates.map((value, index) => neededValues[index]),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      })

      const indexDate2 = new Date().getTime()

      last30Timestamps.forEach((timestamp, index) => {
        if (Math.round((indexDate2 - timestamp) / (1000 * 60 * 60 * 24)) >= 7) {
          last30Days.splice(index, 1)
          last30Timestamps.splice(index, 1)
          last30Values.splice(index, 1)
        }
      })

      const neededDates2 = [
        new Date(indexDate - 0).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 1)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 2)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 3)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 4)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 5)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 6)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 7)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 8)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 9)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 10)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 11)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 12)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 13)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 14)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 15)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 16)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 17)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 18)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 19)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 20)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 21)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 22)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 23)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 24)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 25)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 26)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 27)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 28)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 29)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
      ]

      neededDates2.forEach((log, index) => {
        if (log.split(".")[0].length === 1) {
          neededDates2[index] = `0${log.split(".")[0]}.${log.split(".")[1]}.${log.split(".")[2]}`
        }
      })

      const neededValues2 = []

      neededDates2.forEach((day, index) => {
        if (last30Days.includes(day)) {
          try {
            if (last30Days.length > 0)  {
              const thatIndex = last30Days.indexOf(day)
              neededValues2[index] = last30Values[thatIndex]
            }
          } catch (error) {
            console.log(error);
          }

        } else {
          neededValues2[index] = 0
        }
      })

      // console.log("DATES 30:", neededDates2);
      // console.log("VALUES 30:", neededValues2);


      neededDates2.reverse()
      neededValues2.reverse()

      const lastDates2 = []

      neededDates2.forEach((date) => {
        lastDates2.push(`${date.split(".")[0]}.${date.split(".")[1]}`)
      })

      setStudyTimeLabels30(lastDates2)
      setStudyTimeData30({
        labels: lastDates2,
        datasets: [
          {
            label: 'Ders Çalışma Süresi',
            data: lastDates2.map((value, index) => neededValues2[index]),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
      })
    }
  }, [userData])

  const [solvedQuestionsLabels7, setSolvedQuestionsLabels7] = useState([])

  const [solvedQuestionsData7, setSolvedQuestionsData7] = useState({
    labels: solvedQuestionsLabels7,
    datasets: [
      {
        label: 'Çözülen Soru Sayısı',
        data: solvedQuestionsLabels7.map(() => 0),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  })

  const [solvedQuestionsLabels30, setSolvedQuestionsLabels30] = useState([])

  const [solvedQuestionsData30, setSolvedQuestionsData30] = useState({
    labels: solvedQuestionsLabels30,
    datasets: [
      {
        label: 'Çözülen Soru Sayısı',
        data: solvedQuestionsLabels30.map(() => 0),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  })

  useEffect(() => {
    const today = new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })

    const logs = userData.solvedQuestionsLogs

    if (logs) {
      logs.sort((log1, log2) => log2.timestamp - log1.timestamp)

      logs.forEach((log, index) => {
        if (log.date.split(".")[0].length === 1) {
          log.date = `0${log.date.split(".")[0]}.${log.date.split(".")[1]}.${log.date.split(".")[2]}`
        }
      })

      const dates = []
      const timestamps = []
      const values = []

      logs.forEach((log) => {
        if (dates.includes(log.date)) {
          try {
            if (dates.length > 0) {
              const findedIndex = dates.indexOf(log.date)
              const oldValue = values[findedIndex]
              const newValue = Number(oldValue) + Number(log.value)
              values[findedIndex] = newValue
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          dates.push(log.date)
          timestamps.push(log.timestamp)
          values.push(Number(log.value))
        }
      })

      let last7Days = []
      let last7Timestamps = []
      let last7Values = []

      let last30Days = []
      let last30Timestamps = []
      let last30Values = []


      if (dates[0] === today) {
        if (dates.length >= 8) {
          last7Days = dates.slice(0, 7)
          last7Timestamps = timestamps.slice(0, 7)
          last7Values = values.slice(0, 7)
        } else {
          last7Days = dates.slice()
          last7Timestamps = timestamps.slice()
          last7Values = values.slice()
        }
      } else {
        if (dates.length >= 8) {
          last7Days = dates.slice(0, 7)
          last7Timestamps = timestamps.slice(0, 7)
          last7Values = values.slice(0, 7)
        } else {
          last7Days = dates.slice()
          last7Timestamps = timestamps.slice()
          last7Values = values.slice()
        }
      }

      if (dates[0] === today) {
        if (dates.length >= 31) {
          last30Days = dates.slice(0, 30)
          last30Timestamps = timestamps.slice(0, 30)
          last30Values = values.slice(0, 30)
        } else {
          last30Days = dates.slice()
          last30Timestamps = timestamps.slice()
          last30Values = values.slice()
        }
      } else {
        if (dates.length >= 31) {
          last30Days = dates.slice(0, 30)
          last30Timestamps = timestamps.slice(0, 30)
          last30Values = values.slice(0, 30)
        } else {
          last30Days = dates.slice()
          last30Timestamps = timestamps.slice()
          last30Values = values.slice()
        }
      }

      const indexDate = new Date().getTime()

      last7Timestamps.forEach((timestamp, index) => {
        if (Math.round((indexDate - timestamp) / (1000 * 60 * 60 * 24)) >= 7) {
          last7Days.splice(index, 1)
          last7Timestamps.splice(index, 1)
          last7Values.splice(index, 1)
        }
      })

      const neededDates = [
        new Date(indexDate).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 1)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 2)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 3)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 4)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 5)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 6)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })
      ]

      neededDates.forEach((log, index) => {
        if (log.split(".")[0].length === 1) {
          neededDates[index] = `0${log.split(".")[0]}.${log.split(".")[1]}.${log.split(".")[2]}`
        }
      })

      const neededValues = []
      

      neededDates.forEach((day, index) => {
        if (last7Days.includes(day)) {
          try {
            if (last7Days.length > 0) {
              const thatIndex = last7Days.indexOf(day)
              neededValues[index] = last7Values[thatIndex]
            }
     
          } catch (error) {
            console.log(error);
          }

        } else {
          neededValues[index] = 0
        }
      })

      // console.log("DATES 7:", neededDates);
      // console.log("VALUES 7:", neededValues);

      neededDates.reverse()
      neededValues.reverse()

      const lastDates = []

      neededDates.forEach((date) => {
        lastDates.push(`${date.split(".")[0]}.${date.split(".")[1]}`)
      })

      setSolvedQuestionsLabels7(lastDates)
      setSolvedQuestionsData7({
        labels: lastDates,
        datasets: [
          {
            label: 'Çözülen Soru Sayısı',
            data: lastDates.map((value, index) => neededValues[index]),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      })

      const indexDate2 = new Date().getTime()

      last30Timestamps.forEach((timestamp, index) => {
        if (Math.round((indexDate2 - timestamp) / (1000 * 60 * 60 * 24)) >= 7) {
          last30Days.splice(index, 1)
          last30Timestamps.splice(index, 1)
          last30Values.splice(index, 1)
        }
      })

      const neededDates2 = [
        new Date(indexDate - 0).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 1)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 2)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 3)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 4)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 5)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 6)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 7)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 8)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 9)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 10)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 11)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 12)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 13)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 14)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 15)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 16)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 17)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 18)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 19)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 20)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 21)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 22)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 23)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 24)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 25)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 26)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 27)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 28)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
        new Date(indexDate - (86400000 * 29)).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
      ]

      neededDates2.forEach((log, index) => {
        if (log.split(".")[0].length === 1) {
          neededDates2[index] = `0${log.split(".")[0]}.${log.split(".")[1]}.${log.split(".")[2]}`
        }
      })

      const neededValues2 = []

      neededDates2.forEach((day, index) => {
        if (last30Days.includes(day)) {
          try {
            if (last30Days.length > 0) {
              const thatIndex = last30Days.indexOf(day)
              neededValues2[index] = last30Values[thatIndex]
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          neededValues2[index] = 0
        }
      })

      // console.log("DATES 30:", neededDates2);
      // console.log("VALUES 30:", neededValues2);

      neededDates2.reverse()
      neededValues2.reverse()

      const lastDates2 = []

      neededDates2.forEach((date) => {
        lastDates2.push(`${date.split(".")[0]}.${date.split(".")[1]}`)
      })

      setSolvedQuestionsLabels30(lastDates2)
      setSolvedQuestionsData30({
        labels: lastDates2,
        datasets: [
          {
            label: 'Çözülen Soru Sayısı',
            data: lastDates2.map((value, index) => neededValues2[index]),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
      })
    }
  }, [userData])

  // burası tyt başlangıç
  const [lgsExamsLabels10, setLGSExamsLabels10] = useState([])

  const [lgsExamsData10, setLGSExamsData10] = useState({
    labels: lgsExamsLabels10,
    datasets: [
      {
        label: 'Net',
        data: lgsExamsLabels10.map(() => 0),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  })

  useEffect(() => {
    const logs = userData.lgsExamsLogs

    if (logs) {
      logs.sort((log1, log2) => log1.timestamp - log2.timestamp)

      const dates = []
      const values = []

      for (let index = 0; index < (logs.length >= 10 ? 10 : logs.length); index++) {
        dates.push(logs[index].date)
        values.push(logs[index].value)
      }

      setLGSExamsLabels10(dates)
      setLGSExamsData10({
        labels: dates,
        datasets: [
          {
            label: 'Net',
            data: dates.map((value, index) => values[index]),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      })
    }
  }, [userData])
  // burası tyt bitiş

  return (
    <>
     <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Genel Analiz</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <div style={{ marginBottom: "24px" }} />
      {
        (userData) &&
        <Container style={{ marginBottom: "24px", }}>
          <Container style={{ display: "flex", flexWrap: "wrap", }}>
            {
              (fiveGradeShow || sixGradeShow || sevenGradeShow || eightGradeShow || lgsShow ) && <Row style={{ width: "100%", display: "flex", justifyContent: "center", rowGap: "24px", marginBottom: "36px" }}>
                {
                  fiveGradeShow && <Col xl={4} lg={6} md={12} sm={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p style={{ textAlign: "center", marginBottom: "12px", fontSize: "0.9rem", maxWidth: "60%" }}>5. Sınıf Ders ve Konu Tamamlama Yüzdelik Grafiği</p>
                    <Pie options={options} style={{ maxHeight: "400px", maxWidth: "400px" }} data={fiveGradeSubjectTrackingData} />
                  </Col>
                }
                {
                  sixGradeShow && <Col xl={4} lg={6} md={12} sm={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p style={{ textAlign: "center", marginBottom: "12px", fontSize: "0.9rem", maxWidth: "60%" }}>6. Sınıf Ders ve Konu Tamamlama Yüzdelik Grafiği</p>
                    <Pie options={options} style={{ maxHeight: "400px", maxWidth: "400px" }} data={sixGradeSubjectTrackingData} />
                  </Col>
                }
                {
                  sevenGradeShow && <Col xl={4} lg={6} md={12} sm={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p style={{ textAlign: "center", marginBottom: "12px", fontSize: "0.9rem", maxWidth: "60%" }}>7. Sınıf Ders ve Konu Tamamlama Yüzdelik Grafiği</p>
                    <Pie options={options} style={{ maxHeight: "400px", maxWidth: "400px" }} data={sevenGradeSubjectTrackingData} />
                  </Col>
                }
                {
                  eightGradeShow && <Col xl={4} lg={6} md={12} sm={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p style={{ textAlign: "center", marginBottom: "12px", fontSize: "0.9rem", maxWidth: "60%" }}>8. Sınıf Ders ve Konu Tamamlama Yüzdelik Grafiği</p>
                    <Pie options={options} style={{ maxHeight: "400px", maxWidth: "400px" }} data={eightGradeSubjectTrackingData} />
                  </Col>
                }
                {
                  lgsShow && <Col xl={4} lg={6} md={12} sm={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p style={{ textAlign: "center", marginBottom: "12px", fontSize: "0.9rem", maxWidth: "60%" }}>LGS Ders ve Konu Tamamlama Yüzdelik Grafiği</p>
                    <Pie options={options} style={{ maxHeight: "400px", maxWidth: "400px" }} data={lgsSubjectTrackingData} />
                  </Col>
                }

              </Row>
            }
            {
              (userData.studyTimeLogs || userData.solvedQuestionsLogs) && <Row style={{ width: "100%", display: "flex", justifyContent: "center", rowGap: "24px", marginBottom: "24px" }}>
                {
                  userData.studyTimeLogs && <Col xl={6} lg={6} md={12} sm={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p style={{ textAlign: "center", marginBottom: "6px", fontSize: "0.9rem", maxWidth: "60%", }}>Haftalık Ders Çalışma Süresi Grafiği</p>
                    <Line options={options} data={studyTimeData7} />
                  </Col>
                }
                {
                  userData.studyTimeLogs && <Col xl={6} lg={6} md={12} sm={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p style={{ textAlign: "center", marginBottom: "6px", fontSize: "0.9rem", maxWidth: "60%", }}>Aylık Ders Çalışma Süresi Grafiği</p>
                    <Line options={options} data={studyTimeData30} />
                  </Col>
                }
                {
                  userData.solvedQuestionsLogs && <Col xl={6} lg={6} md={12} sm={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p style={{ textAlign: "center", marginBottom: "6px", fontSize: "0.9rem", maxWidth: "60%", }}>Haftalık Çözülen Soru Sayısı Grafiği</p>
                    <Line options={options} data={solvedQuestionsData7} />
                  </Col>
                }
                {
                  userData.solvedQuestionsLogs && <Col xl={6} lg={6} md={12} sm={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p style={{ textAlign: "center", marginBottom: "6px", fontSize: "0.9rem", maxWidth: "60%", }}>Aylık Çözülen Soru Sayısı Grafiği</p>
                    <Line options={options} data={solvedQuestionsData30} />
                  </Col>
                }
              </Row>
            }
            {
              (userData.lgsExamsLogs ) && <Row style={{ width: "100%", display: "flex", justifyContent: "center", rowGap: "24px", marginBottom: "24px" }}>
                {
                  (userData.lgsExamsLogs && userData.lgsExamsLogs.length > 0) && <Col xl={6} lg={6} md={12} sm={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p style={{ textAlign: "center", marginBottom: "6px", fontSize: "0.9rem", maxWidth: "60%", }}>Son Çözülen 10 LGS Denemesi Net Grafiği</p>
                    <Line options={options} data={lgsExamsData10} />
                  </Col>
                }
              </Row>

            }

          </Container>
        </Container>
      }
    </>
  )
}
