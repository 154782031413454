import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Navbar, Container, Accordion, Col, Row, Form } from 'react-bootstrap'
import { db } from '../../firebaseConfig';
import lessons from "../../datas/lessonsForLgs.json"
import subjects from "../../datas/subjectsForLgs.json"
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export default function SubjectTrackingReport() {

  const params = useParams();
  const navigate = useNavigate()

  const [studentData, setStudentData] = useState()

  const { userData } = useAuth()

  useEffect(() => {
    const getStudentData = async () => {
      const uid = params.id
      const docRef = doc(db, "students", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data()
        if (data.organizationId === userData.uid) {
          setStudentData(docSnap.data())

        } else {
          navigate("/kurum-paneli/denemeler")

        }
      } else {
        navigate("/kurum-paneli/denemeler")
      }
    }
    getStudentData()
  }, [params.id, navigate, userData])

  return (
    <>
      <Navbar style={{ overflowX: "hidden"}}  expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Öğrenciler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/kurum-paneli/konu-takibi/' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Konu Takibi</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              {/* tıklanabilir */}
              
              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Öğrenci Raporu</p>
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      {
        studentData &&
        <Navbar style={{ marginBottom: "24px", overflow: "hidden" }} expand="lg" className="bg-white border-bottom">
          <Container>
            <Navbar.Brand>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "400" }}><span style={{ color: "#000000", fontWeight: "500"}}>{`${studentData.name} ${studentData.surname}`}</span> {`${studentData.studentNo ? `(${studentData.studentNo})` : ""} ${studentData.classCode ? `(${studentData.grade}-${studentData.classCode})` : `(${studentData.grade})`} ${studentData.lang ? `(${studentData.domain}-${studentData.lang})` : studentData.domain ? `(${studentData.domain})` : ""}`}</p>
              </div>
            </Navbar.Brand>
          </Container>
        </Navbar>
      }
      {
        (studentData) &&
        <Container style={{ marginBottom: "24px" }}>
          <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
            <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Col lg={3} md={2} sm={1} />
              <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
                <Accordion alwaysOpen>
                  {
                    ["5", "6", "7", "8"].map((grade, index) => {

                      let numberOfSubjects = 0

                      subjects.forEach((subject) => {
                        if (subject.grade === grade) {
                          numberOfSubjects += 1
                        }
                      })

                      let checkedSubjects = 0

                      lessons.forEach((lesson) => {
                        subjects.forEach((subject) => {
                          if (subject.lessonId === lesson.uid && subject.grade === grade) {
                            const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                            if (studentData.subjectTracking) {
                              if (studentData.subjectTracking[codingKey]) {
                                checkedSubjects += 1
                              }
                            }
                          }
                        })
                      })

                      const persentageOfSubjectCompletion = Math.round((checkedSubjects / numberOfSubjects) * 100)


                      return (
                        <Accordion.Item key={index} eventKey={index + (Number(grade) * 100)}>
                          <Accordion.Header>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                              <p style={{ padding: 0, margin: 0 }}>{grade}. Sınıf</p>
                              <p style={{ padding: 0, margin: "0 8px 0 0", }}>(%{persentageOfSubjectCompletion || 0})</p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body style={{ backgroundColor: "#F8F9FA" }}>
                            {
                              lessons && lessons.map((lesson, index) => {
                                if (lesson.grades.includes(grade)) {

                                  let numberOfSubjects = 0

                                  subjects.forEach((subject) => {
                                    if (subject.lessonId === lesson.uid && subject.grade === grade) {
                                      numberOfSubjects += 1
                                    }
                                  })

                                  let checkedSubjects = 0

                                  subjects.forEach((subject) => {
                                    if (subject.lessonId === lesson.uid && subject.grade === grade) {
                                      const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                                      if (studentData.subjectTracking) {
                                        if (studentData.subjectTracking[codingKey]) {
                                          checkedSubjects += 1
                                        }
                                      }
                                    }
                                  })

                                  const persentageOfSubjectCompletion = Math.round((checkedSubjects / numberOfSubjects) * 100)

                                  return (
                                    <Accordion.Item key={index} eventKey={index + (Number(grade) * 200)}>
                                      <Accordion.Header>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                          <p style={{ padding: 0, margin: 0 }}>{lesson.title}</p>
                                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>(%{persentageOfSubjectCompletion || 0})</p>
                                        </div>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <Container style={{ display: "flex", flexDirection: "column", gap: "16px 0" }}>
                                          {
                                            subjects && subjects.map((subject, index) => {
                                              if (subject.grade === grade && subject.lessonId === lesson.uid) {
                                                const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                                                return (
                                                  <Row key={index}>
                                                    <div style={{ backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #dee2e6", padding: "12px 16px", borderRadius: "0.375rem" }}>
                                                      <p style={{ margin: 0, fontSize: "1rem", padding: 0 }}>{subject.title}</p>
                                                      <Form style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
                                                        <Form.Check.Input id="read-only-checkbox" readOnly checked={(studentData.subjectTracking && studentData.subjectTracking[codingKey]) || false} style={{ borderWidth: "1.5px", width: "19px", height: "19px", margin: 0 }} />
                                                      </Form>
                                                    </div>
                                                  </Row>
                                                )
                                              } else {
                                                return null
                                              }
                                            })
                                          }
                                        </Container>

                                      </Accordion.Body>
                                    </Accordion.Item>
                                  )
                                } else {
                                  return null
                                }
                              })
                            }
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    })
                  }
                </Accordion>
              </Col>
              <Col lg={3} md={2} sm={1} />
            </Row>
          </Container>
        </Container>
      }
    </>
  )
}
