import React, { useEffect, useState } from 'react'
import { Navbar, Container, Button, Spinner, Row, Col, Card, Form, FloatingLabel, Modal } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function AddNewExam() {

  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clicked, setClicked] = useState(false)

  const { createNewExam, userData } = useAuth();

  const navigate = useNavigate()

  const handleFocus = (event) => event.target.select();

  const handleClick = async () => {
    setClicked(true)
    console.log("userdata", userData);
    console.log("name", name);
    console.log("type", type);
    if (userData && name && type) {
      if (type === "grade") {
        if (grade && questionNumber && turkishTrue && turkishFalse && turkishEmpty && socialTrue && socialFalse && socialEmpty && dinTrue  && dinFalse  && dinEmpty  && ingTrue  && ingFalse  && ingEmpty && mathTrue && mathFalse && mathEmpty && scienceTrue && scienceFalse && scienceEmpty) {
          if (Number(questionNumber) === (Number(turkishTrue) + Number(turkishFalse) + Number(turkishEmpty) + Number(socialTrue) + Number(socialFalse) + Number(socialEmpty) + Number(dinTrue) + Number(dinFalse) + Number(dinEmpty) + Number(ingTrue) + Number(ingFalse) + Number(ingEmpty) + Number(mathTrue) + Number(mathFalse) + Number(mathEmpty) + Number(scienceTrue) + Number(scienceFalse) + Number(scienceEmpty))) {
            const turkishNet = (Number(turkishTrue) - (Number(turkishFalse) / 3)).toFixed(2)
            const socialNet = (Number(socialTrue) - (Number(socialFalse) / 3)).toFixed(2)
            const dinNet = (Number(dinTrue) - (Number(dinFalse) / 3)).toFixed(2)
            const ingNet = (Number(ingTrue) - (Number(dinFalse) / 3)).toFixed(2)
            const mathNet = (Number(mathTrue) - (Number(mathFalse) / 3)).toFixed(2)
            const scienceNet = (Number(scienceTrue) - (Number(scienceFalse) / 3)).toFixed(2)
            const totalNet = (Number(turkishNet) + Number(socialNet) + Number(dinNet) + Number(ingNet) + Number(mathNet) + Number(scienceNet)).toFixed(2)

            try {
              await createNewExam({
                studentId: userData.uid,
                name: name.trim(),
                type: type,
                grade: grade,
                questionNumber: Number(questionNumber),
                turkishTrue: Number(turkishTrue),
                turkishFalse: Number(turkishFalse),
                turkishEmpty: Number(turkishEmpty),
                socialTrue: Number(socialTrue),
                socialFalse: Number(socialFalse),
                socialEmpty: Number(socialEmpty),
                dinTrue: Number(dinTrue),
                dinFalse: Number(dinFalse),
                dinEmpty: Number(dinEmpty),
                ingTrue: Number(ingTrue),
                ingFalse: Number(ingFalse),
                ingEmpty: Number(ingEmpty),
                mathTrue: Number(mathTrue),
                mathFalse: Number(mathFalse),
                mathEmpty: Number(mathEmpty),
                scienceTrue: Number(scienceTrue),
                scienceFalse: Number(scienceFalse),
                scienceEmpty: Number(scienceEmpty),
                turkishNet: Number(turkishNet),
                socialNet: Number(socialNet),
                dinNet: Number(dinNet),
                ingNet: Number(ingNet),
                mathNet: Number(mathNet),
                scienceNet: Number(scienceNet),
                totalNet: Number(totalNet)
              })
              setClicked(false)
              navigate("/ogrenci-paneli/denemeler")
            } catch (error) {
              setClicked(false)
              // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
              setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
              handleShow()
            }
          } else {
            setClicked(false)
            setModalError("Girmiş olduğunuz doğru yanlış ve boş sayıları toplamı deneme sınavının soru sayısına eşit değildir. Lütfen kontrol ediniz.")
            handleShow()
          }
        } else {
          setClicked(false)
          setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
          handleShow()
        }
      }
      else if (type === "branch") {
        if (questionNumber2 && lesson && generalTrue && generalFalse && generalEmpty) {
          if (Number(questionNumber2) === (Number(generalTrue) + Number(generalFalse) + Number(generalEmpty))) {
            const totalNet = (Number(generalTrue) - (Number(generalFalse) / 3)).toFixed(2)

            try {
              await createNewExam({
                studentId: userData.uid,
                name: name.trim(),
                type: type,
                lesson: lesson,
                questionNumber: Number(questionNumber2),
                generalTrue: Number(generalTrue),
                generalFalse: Number(generalFalse),
                generalEmpty: Number(generalEmpty),
                totalNet: Number(totalNet)
              })
              setClicked(false)
              navigate("/ogrenci-paneli/denemeler")
            } catch (error) {
              setClicked(false)
              // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
              setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
              handleShow()
            }
          } else {
            setClicked(false)
            setModalError("Girmiş olduğunuz doğru yanlış ve boş sayıları toplamı deneme sınavının soru sayısına eşit değildir. Lütfen kontrol ediniz.")
            handleShow()
          }
        } else {
          setClicked(false)
          setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
          handleShow()
        }
      }

      else if (type === "lgs") {
        if (questionNumber3 && turkishTrue3 && turkishFalse3 && turkishEmpty3 && socialTrue3 && socialFalse3 && socialEmpty3 && dinTrue3 && dinFalse3 && dinEmpty3 && mathTrue3 && mathFalse3 && mathEmpty3 && scienceTrue3 && scienceFalse3 && scienceEmpty3) {
          if (Number(questionNumber3) === (Number(turkishTrue3) + Number(turkishFalse3) + Number(turkishEmpty3) + Number(socialTrue3) + Number(socialFalse3) + Number(socialEmpty3) + Number(dinTrue3) + Number(dinFalse3) + Number(dinEmpty3) + Number(ingTrue3) + Number(ingFalse3) + Number(ingEmpty3) + Number(mathTrue3) + Number(mathFalse3) + Number(mathEmpty3) + Number(scienceTrue3) + Number(scienceFalse3) + Number(scienceEmpty3))) {
            const turkishNet = (Number(turkishTrue3) - (Number(turkishFalse3) / 3)).toFixed(2)
            const socialNet = (Number(socialTrue3) - (Number(socialFalse3) / 3)).toFixed(2)
            const dinNet = (Number(dinTrue3) - (Number(dinFalse3) / 3)).toFixed(2)
            const ingNet = (Number(ingTrue3) - (Number(ingFalse3) / 3)).toFixed(2)
            const mathNet = (Number(mathTrue3) - (Number(mathFalse3) / 3)).toFixed(2)
            const scienceNet = (Number(scienceTrue3) - (Number(scienceFalse3) / 3)).toFixed(2)
            const totalNet = (Number(turkishNet) + Number(socialNet) + Number(dinNet) + Number(ingNet) + Number(mathNet) + Number(scienceNet)).toFixed(2)

            try {
              await createNewExam({
                studentId: userData.uid,
                name: name.trim(),
                type: type,
                questionNumber: Number(questionNumber3),
                turkishTrue: Number(turkishTrue3),
                turkishFalse: Number(turkishFalse3),
                turkishEmpty: Number(turkishEmpty3),
                socialTrue: Number(socialTrue3),
                socialFalse: Number(socialFalse3),
                socialEmpty: Number(socialEmpty3),
                dinTrue: Number(dinTrue3),
                dinFalse: Number(dinFalse3),
                dinEmpty: Number(dinEmpty3),
                ingTrue: Number(ingTrue3),
                ingFalse: Number(ingFalse3),
                ingEmpty: Number(ingEmpty3),
                mathTrue: Number(mathTrue3),
                mathFalse: Number(mathFalse3),
                mathEmpty: Number(mathEmpty3),
                scienceTrue: Number(scienceTrue3),
                scienceFalse: Number(scienceFalse3),
                scienceEmpty: Number(scienceEmpty3),
                turkishNet: Number(turkishNet),
                socialNet: Number(socialNet),
                dinNet: Number(dinNet),
                ingNet: Number(ingNet),
                mathNet: Number(mathNet),
                scienceNet: Number(scienceNet),
                totalNet: Number(totalNet)
              })
              setClicked(false)
              navigate("/ogrenci-paneli/denemeler")
            } catch (error) {
              setClicked(false)
              // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
              setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
              handleShow()
            }
          } else {
            setClicked(false)
            setModalError("Girmiş olduğunuz doğru yanlış ve boş sayıları toplamı deneme sınavının soru sayısına eşit değildir. Lütfen kontrol ediniz.")
            handleShow()
          }
        } else {
          setClicked(false)
          setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
          handleShow()
        }
      }
    } else {
      setClicked(false)
      setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
      handleShow()
    }
  }

  const [name, setName] = useState("")
  const [type, setType] = useState("grade")

  // sınıf denemesi ise
  const [grade, setGrade] = useState("5")
  const [questionNumber, setQuestionNumber] = useState("75")

  const [turkishTrue, setTurkishTrue] = useState("0")
  const [turkishFalse, setTurkishFalse] = useState("0")
  const [turkishEmpty, setTurkishEmpty] = useState("0")

  const [socialTrue, setSocialTrue] = useState("0")
  const [socialFalse, setSocialFalse] = useState("0")
  const [socialEmpty, setSocialEmpty] = useState("0")

  const [dinTrue, setDinTrue] = useState("0")
  const [dinFalse, setDinFalse] = useState("0")
  const [dinEmpty, setDinEmpty] = useState("0")

  const [ingTrue, setIngTrue] = useState("0")
  const [ingFalse, setIngFalse] = useState("0")
  const [ingEmpty, setIngEmpty] = useState("0")

  const [mathTrue, setMathTrue] = useState("0")
  const [mathFalse, setMathFalse] = useState("0")
  const [mathEmpty, setMathEmpty] = useState("0")

  const [scienceTrue, setScienceTrue] = useState("0")
  const [scienceFalse, setScienceFalse] = useState("0")
  const [scienceEmpty, setScienceEmpty] = useState("0")

  // branş denemesi ise
  const [lesson, setLesson] = useState("Türkçe")
  const [questionNumber2, setQuestionNumber2] = useState("0")
  const [generalTrue, setGeneralTrue] = useState("0")
  const [generalFalse, setGeneralFalse] = useState("0")
  const [generalEmpty, setGeneralEmpty] = useState("0")


  // lgs denemesi ise
  const [questionNumber3, setQuestionNumber3] = useState("90")

  const [turkishTrue3, setTurkishTrue3] = useState("0")
  const [turkishFalse3, setTurkishFalse3] = useState("0")
  const [turkishEmpty3, setTurkishEmpty3] = useState("0")

  const [socialTrue3, setSocialTrue3] = useState("0")
  const [socialFalse3, setSocialFalse3] = useState("0")
  const [socialEmpty3, setSocialEmpty3] = useState("0")

  const [dinTrue3, setDinTrue3] = useState("0")
  const [dinFalse3, setDinFalse3] = useState("0")
  const [dinEmpty3, setDinEmpty3] = useState("0")

  const [ingTrue3, setIngTrue3] = useState("0")
  const [ingFalse3, setIngFalse3] = useState("0")
  const [ingEmpty3, setIngEmpty3] = useState("0")

  const [mathTrue3, setMathTrue3] = useState("0")
  const [mathFalse3, setMathFalse3] = useState("0")
  const [mathEmpty3, setMathEmpty3] = useState("0")

  const [scienceTrue3, setScienceTrue3] = useState("0")
  const [scienceFalse3, setScienceFalse3] = useState("0")
  const [scienceEmpty3, setScienceEmpty3] = useState("0")


  useEffect(() => {
    if (lesson) {
      if (type === "branch") {
        switch (lesson) {
          case "Türkçe":
            setQuestionNumber2("20")
            break;
          case "Sosyal":
            setQuestionNumber2("10")
            break;
          case "Din Kültürü":
            setQuestionNumber2("10")
            break;
          case "İngilizce":
            setQuestionNumber2("10")
            break;
          case "Matematik":
            setQuestionNumber2("20")
            break;
          case "Geometri":
            setQuestionNumber2("10")
            break;
          case "Fen":
            setQuestionNumber2("20")
            break;
          default:
            break;
        }
      }
    }
  }, [lesson, type])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/ogrenci-paneli/denemeler' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Denemeler</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Deneme Ekle</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
          <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Col lg={3} md={2} sm={1} />
            <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
              <Card >
                <Card.Body>
                  <Form onSubmit={e => { e.preventDefault(); }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput1"
                        label="Deneme Adı *"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Ad*" value={name} onChange={(e) => setName(e.target.value)} />
                      </FloatingLabel>
                    </Form.Group>

                    <FloatingLabel controlId="floatingSelect" label="Deneme Türü *">
                      <Form.Select className="mb-3" value={type} onChange={(e) => setType(e.target.value)} aria-label="Default select example">
                        <option value="grade">Sınıf Denemesi</option>
                        <option value="branch">Branş Denemesi</option>
                        <option value="lgs">LGS Denemesi</option>
                      </Form.Select>
                    </FloatingLabel>

                    {
                      (type === "grade") &&
                      <>
                        <FloatingLabel controlId="floatingSelect" label="Sınıf *">
                          <Form.Select className="mb-3" value={grade} onChange={(e) => setGrade(e.target.value)} aria-label="Default select example">
                            <option value="5">5. Sınıf</option>
                            <option value="6">6. Sınıf</option>
                            <option value="7">7. Sınıf</option>
                          </Form.Select>
                        </FloatingLabel>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <FloatingLabel
                            controlId="floatingInput1"
                            label="Soru Sayısı *"
                            className="mb-3"
                          >
                            <Form.Control type='number' placeholder="" value={questionNumber} onChange={(e) => setQuestionNumber(e.target.value)} />
                          </FloatingLabel>
                        </Form.Group>

                        <div style={{ padding: "6px 12px 6px 12px", marginBottom: "6px" }}>
                          <Row className="mb-3" >
                            <Col></Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }} >Doğru</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Yanlış</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Boş</Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Türkçe:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishTrue} onChange={(e) => setTurkishTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishFalse} onChange={(e) => setTurkishFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishEmpty} onChange={(e) => setTurkishEmpty(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Sosyal:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialTrue} onChange={(e) => setSocialTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialFalse} onChange={(e) => setSocialFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialEmpty} onChange={(e) => setSocialEmpty(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Din Kültürü:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={dinTrue} onChange={(e) => setDinTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={dinFalse} onChange={(e) => setDinFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={dinEmpty} onChange={(e) => setDinEmpty(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>İngilizce:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={ingTrue} onChange={(e) => setIngTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={ingFalse} onChange={(e) => setIngFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={ingEmpty} onChange={(e) => setIngEmpty(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Mat:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathTrue} onChange={(e) => setMathTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathFalse} onChange={(e) => setMathFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathEmpty} onChange={(e) => setMathEmpty(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Fen:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceTrue} onChange={(e) => setScienceTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceFalse} onChange={(e) => setScienceFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceEmpty} onChange={(e) => setScienceEmpty(e.target.value)} />
                            </Col>
                          </Row>
                        </div>
                      </>
                    }

                    {
                      (type === "branch") &&
                      <>
                        {
                          <FloatingLabel controlId="floatingSelect" label="Ders *">
                            <Form.Select className="mb-3" value={lesson} onChange={(e) => setLesson(e.target.value)} aria-label="Default select example">
                              <option value="Türkçe">Türkçe</option>
                              <option value="Sosyal">Sosyal</option>
                              <option value="Din Kültürü">Din Kültürü</option>
                              <option value="İngilizce">İngilizce</option>
                              <option value="Matematik">Matematik</option>
                              <option value="Geometri">Geometri</option>
                              <option value="Fen">Fen</option>
                            </Form.Select>
                          </FloatingLabel>
                        }
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <FloatingLabel
                            controlId="floatingInput1"
                            label="Soru Sayısı *"
                            className="mb-3"
                          >
                            <Form.Control type='number' placeholder="" value={questionNumber2} onChange={(e) => setQuestionNumber2(e.target.value)} />
                          </FloatingLabel>
                        </Form.Group>

                        <div style={{ padding: "6px 12px 6px 12px", marginBottom: "6px" }}>
                          <Row className="mb-3" >
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }} >Doğru</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Yanlış</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Boş</Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={generalTrue} onChange={(e) => setGeneralTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={generalFalse} onChange={(e) => setGeneralFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={generalEmpty} onChange={(e) => setGeneralEmpty(e.target.value)} />
                            </Col>
                          </Row>
                        </div>
                      </>
                    }
                    {
                      (type === "lgs") &&
                      <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <FloatingLabel
                            controlId="floatingInput1"
                            label="Soru Sayısı *"
                            className="mb-3"
                          >
                            <Form.Control disabled type='number' placeholder="" value={questionNumber3} onChange={(e) => setQuestionNumber3(e.target.value)} />
                          </FloatingLabel>
                        </Form.Group>

                        <div style={{ padding: "6px 12px 6px 12px", marginBottom: "6px" }}>
                          <Row className="mb-3" >
                            <Col></Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }} >Doğru</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Yanlış</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Boş</Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Türkçe:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishTrue3} onChange={(e) => setTurkishTrue3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishFalse3} onChange={(e) => setTurkishFalse3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishEmpty3} onChange={(e) => setTurkishEmpty3(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Sosyal:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialTrue3} onChange={(e) => setSocialTrue3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialFalse3} onChange={(e) => setSocialFalse3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialEmpty3} onChange={(e) => setSocialEmpty3(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Din Kültürü:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={dinTrue3} onChange={(e) => setDinTrue3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={dinFalse3} onChange={(e) => setDinFalse3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={dinEmpty3} onChange={(e) => setDinEmpty3(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>İngilizce:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={ingTrue3} onChange={(e) => setIngTrue3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={ingFalse3} onChange={(e) => setIngFalse3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={ingEmpty3} onChange={(e) => setIngEmpty3(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Mat:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathTrue3} onChange={(e) => setMathTrue3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathFalse3} onChange={(e) => setMathFalse3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathEmpty3} onChange={(e) => setMathEmpty3(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Fen:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceTrue3} onChange={(e) => setScienceTrue3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceFalse3} onChange={(e) => setScienceFalse3(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceEmpty3} onChange={(e) => setScienceEmpty3(e.target.value)} />
                            </Col>
                          </Row>
                        </div>
                      </>
                    }

                    <div style={{ margin: "0 0 12px 0", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: 0 }}>
                      <Form.Text style={{ marginTop: 0, textAlign: "center" }} className="text-muted">
                        Yıldız (*) işareti bulunan alanları doldurmak zorunludur.
                      </Form.Text>
                    </div>

                    {
                      clicked
                        ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                          <Spinner
                            style={{ marginRight: "6px" }}
                            as="span"
                            size="sm"
                            aria-hidden="true"
                          />
                        </Button>
                        : <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                          Deneme Ekle
                        </Button>
                    }

                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={2} sm={1} />
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>{modalTitle}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {modalError}
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}


