import React, { useEffect, useState } from 'react'
import { Navbar, Container, Table, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig"
import OrganizationRow from './OrganizationRow';

export default function OrganizationList() {

  const navigate = useNavigate();

  const [organizations, setOrganizations] = useState([])
  const [totalStudents, setTotalStudents] = useState(0)
  const [totalTeachers, setTotalTeachers] = useState(0)

  const getOrganizations = async () => {
    const organizations = [];
    const querySnapshot = await getDocs(collection(db, "organizations"));
    querySnapshot.forEach((doc) => {
      organizations.push(doc.data())
    });
    organizations.sort((x, y) => {
      return y.createdAt - x.createdAt
    })
    setOrganizations(organizations)
  }

  useEffect(() => {
    getOrganizations()
  }, [])

  useEffect(() => {
    if (organizations) {
      calculateTotalStudentsAndTeachers()
    }
  }, [organizations])

  const calculateTotalStudentsAndTeachers = async () => {
    let totalStudents = 0
    let totalTeachers = 0

    const querySnapshot = await getDocs(collection(db, "students"));
    querySnapshot.forEach(() => {
      totalStudents += 1
    });

    const querySnapshot2 = await getDocs(collection(db, "teachers"));
    querySnapshot2.forEach(() => {
      totalTeachers += 1
    });

    setTotalStudents(totalStudents)
    setTotalTeachers(totalTeachers)
  }

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Kurumlar</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Kurum Listesi</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid style={{ marginBottom: "24px" }}>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th style={{ width: "17%" }}>Toplam Kurum</th>
              <th style={{ width: "17%" }}>Toplam Öğrenci</th>
              <th style={{ width: "17%" }}>Toplam Öğretmen</th>
              <th style={{ width: "17%" }}>Toplam Veli</th>
              <th style={{ width: "17%" }}>Toplam Kullanıcı</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ verticalAlign: "middle" }}>
              <td>{organizations ? organizations.length : 0}</td>
              <td>{totalStudents}</td>
              <td>{totalTeachers}</td>
              <td>{totalStudents}</td>
              <td>{organizations ? (organizations.length + totalStudents + totalTeachers + totalStudents) : 0}</td>
              <td style={{ textAlign: "center" }}>
                <Button onClick={() => navigate("kurum-ekle")} size="sm" >Kurum Ekle</Button>
              </td>
            </tr>
          </tbody>
        </Table>

        {
          organizations ?
            organizations.length > 0 ?
              <Table fluid style={{ marginTop: '8px'}} striped bordered responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{ width: "16.5%" }}>Kurum</th>
                    <th style={{ width: "16.5%" }}>UID</th>
                    <th style={{ width: "11.75%" }}>Adres</th>
                    <th style={{ width: "11.75%" }}>Telefon</th>
                    <th style={{ width: "16.5%" }}>Kullanıcı Adı</th>
                    <th style={{ width: "11.75%" }}>Parola</th>
                    <th style={{ width: "7%" }}>Öğrenci</th>
                    <th style={{ width: "7%" }}>Öğretmen</th>
                    <th style={{ width: "15%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    organizations.map((organization, index) => {
                      return (
                        <OrganizationRow key={index} organization={organization} index={index} />
                      )
                    })
                  }
                </tbody>
              </Table>
              : null
            : null

        }

      </Container>
    </>
  )
}
