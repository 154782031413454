import React, { useEffect, useState } from 'react'
import { Navbar, Container, Table } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

export default function ParentList() {

  const [parents, setParents] = useState([])

  const { userData } = useAuth();

  useEffect(() => {
    if (userData) {
      const getParents = async () => {
        const parents = [];
        const querySnapshot = await getDocs(query(collection(db, "parents"), where("organizationId", "==", userData.uid)));
        querySnapshot.forEach((doc) => {
          parents.push(doc.data())
        });
        parents.sort((x, y) => {
          return y.createdAt - x.createdAt
        })
        setParents(parents)
      }

      getParents()
    }
  }, [userData])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Veliler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Veli Listesi</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th style={{ width: "12.5%" }}>Toplam</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ verticalAlign: "middle" }}>
              <td>{parents ? parents.length : 0}</td>
            </tr>
          </tbody>
        </Table >

        {
          parents
            ? parents.length > 0
              ? <Table style={{ marginTop: '8px' }} striped bordered responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{ width: "19.8%" }}>Veli</th>
                    <th style={{ width: "19.8%" }}>Öğrenci</th>
                    <th style={{ width: "12%" }}>Öğrenci No</th>
                    <th style={{ width: "12%" }}>Sınıf & Şube</th>
                    <th style={{ width: "19.8%" }}>Veli Kullanıcı Adı</th>
                    <th style={{ width: "19.8%" }}>Veli Parola</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    parents.map((parent, index) => {
                      return (
                        <tr key={index} style={{ verticalAlign: "middle" }}>
                          <td>{index + 1}</td>
                          <td>{parent.name}</td>
                          <td>{parent.childName}</td>
                          <td>{parent.childStudentNo}</td>
                          <td>{parent.childGrade} {parent.childClassCode ? ` - ${parent.childClassCode}` : null}</td>
                          <td>{parent.username}</td>
                          <td>{parent.password}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              : null
            : null

        }


      </Container>
    </>
  )
}
